import { Attribute, QuestionSpace } from './program.entity'
import { Question } from './submission.entity'

export enum ApplicationStatus {
  Invited = 'Invited',
  Rejected = 'Rejected',
  Confirmed = 'Confirmed',
  Applied = 'Applied',
  Shortlisted = 'Shortlisted'
}

export interface LearnerReport extends Object {
  _id?: string
  program: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string
  managerFirstName?: string
  managerEmail?: string
  businessUnit?: string
  roleType?: string
  role?: string
  location?: string
  linkedIn?: string
  tncAgreeAt?: Date
  questions?: Question[]
  dates?: string[]
  criteriaJob?: string
  createdAt?: Date
  updatedAt?: Date
  questionSpaces?: QuestionSpace[]

  // rla
  rlaChapterLeadDiscussionConfirmationAt?: Date
  rlaTimeAndLeaveCommitmentConfirmationAt?: Date
  otherLocation?: string

  // generic fields
  jobTitle?: string
  organisation?: string
  phoneNumber?: string
  numberOfLearners?: string
  preferredCourseDate?: string
  otherJobTitle?: string

  // for frontend
  pass?: boolean
  cognify?: number // score
  submissionAttempts?: number
  cognifyAttempts?: number

  status: {
    learnerConfirmation?: boolean
    learnerConfirmationAt?: Date
    learnerConfirmationRequestedAt?: Date
    managerConsent?: boolean
    managerConsentAt?: Date
    managerConsentRequestedAt?: Date
    managerConfirmation?: boolean
    managerConfirmationAt?: Date
    managerConfirmationRequestedAt?: Date
  }

  // for frontend columns
  managerConsent?: string
  managerConfirmation?: string
  learnerConfirmation?: string
  applicationStatus?: string

  logs: [
    {
      data: any
      createdAt: Date
    }
  ]
  log: any

  attributes?: Attribute[]
}
