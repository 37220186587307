import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import './DropDownMenu.css'
import { v4 as uuidv4 } from 'uuid'
import Emitter, { Events } from '../core/emitter'
import CheckboxV2, { CheckboxV2Ref } from './CheckboxV2'
import { CheckboxTheme } from './Checkbox'

export const SHOW_ALL_ID = 'show-all'

export type DropDownItem = {
  id: string
  name: string
  value?: any
  isLabel?: boolean
  isShowAllOptions?: boolean
  default?: boolean
  addition?: {
    text: string
    style: {
      color: string
      fontSize: string
      fontWeight: string
      fontStyle: string
    }
  }
}

type Props = {
  items: DropDownItem[]
  onSelected?: (item: DropDownItem) => void
  value?: string
  colorMapper?: { [key: string]: string }
  onMouseOver?: () => void
  onClick?: () => void
  style?: {
    zIndex?: number
    fontSize?: string
    fontWeight?: string
    textAlign?: 'left' | 'center' | 'right'
    labelColor?: string
    labelFontSize?: string
    labelFontWeight?: string
    justifyItems?: 'stretch' | 'center'
    border?: string
    backgroundColor?: string
  }
  onPreSelected?: (item: DropDownItem, dropDownId: string) => void
  id?: string // for receiving emitted events and ajax calls
  mode?: 'scroll' | 'popup'
  // for multi select
  multiSelect?: boolean
  onMultiSelected?: (items: DropDownItem[]) => void
}

const DropDownMenu = forwardRef(
  (
    {
      items,
      onSelected,
      value,
      colorMapper,
      onMouseOver,
      onClick,
      style,
      onPreSelected,
      id,
      mode,
      multiSelect,
      onMultiSelected
    }: Props,
    ref
  ) => {
    const refId = useRef<string>(id || uuidv4())
    const refMode = useRef<string>(mode || 'scroll')
    const [isShownDropDown, setIsShownDropDown] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<DropDownItem>()
    const refPreSelectedItem = useRef<DropDownItem>()
    const htmlRef = useRef<HTMLDivElement>(null)
    const checkboxesRef = useRef<{
      [key: string]: CheckboxV2Ref
    }>({})

    const getSelectedCheckBoxIds = () => {
      return Object.keys(checkboxesRef.current).filter(key => checkboxesRef.current[key].getChecked())
    }

    const getSelectedCheckBoxes = () => {
      return getSelectedCheckBoxIds().map(id => items.find(item => item.id === id) as DropDownItem)
    }

    const isSelectedItem = (item: DropDownItem) => {
      // console.log('selectedItems', getSelectedCheckBoxIds())
      return getSelectedCheckBoxIds().includes(item.id)
    }

    const isDisabledItem = (item: DropDownItem) => {
      return checkboxesRef.current[item.id]?.getDisabled() || false
    }

    const getItemName = () => {
      if (selectedItem) {
        // check if selected item exists in the items list, otherwise return the first item
        const tmpSelectedItem = items.find(item => item.id === selectedItem.id)
        if (!tmpSelectedItem) {
          setSelectedItem(items[0])
          return items[0].name
        }
        // Check if the selected item is marked with defaultValue: true
        if (selectedItem.isShowAllOptions) {
          // Find the item marked as a label and return its name
          const labelItem = items.find(item => item.isLabel)
          return labelItem ? labelItem.name : ''
        }
        // For other items, return their name as usual
        return selectedItem.name
      }
      return items.length > 0 ? value || items[0].name : ''
    }

    const getMultiSelectItemName = () => {
      const selectedItems = getSelectedCheckBoxes()
      // console.log('selectedItems', selectedItems)
      if (selectedItems.length === 0) {
        return getItemName()
      }
      // get the first selected item name
      return selectedItems[0].name
    }

    // first item name (+ selected items count)
    const renderMultiSelectItemName = () => {
      const selectedItems = getSelectedCheckBoxes()
      const itemName = getMultiSelectItemName()
      const selectedItemsCount = selectedItems.length - 1
      return (
        <div className="flex flex-row gap-[8px]">
          <span id={`multi-select-label-${refId.current}`} style={{ color: getLabelColor(itemName) }}>
            {itemName}
          </span>
          <span
            id={`multi-select-count-${refId.current}`}
            style={{
              color: '#ba61ff',
              fontSize: style?.labelFontSize ? style.labelFontSize : '',
              fontWeight: style?.labelFontWeight ? style.labelFontWeight : '',
              display: selectedItemsCount > 0 ? 'inline' : 'none',
              userSelect: 'none'
            }}>
            (+{selectedItemsCount})
          </span>
        </div>
      )
    }

    const updateMultiSelectItemName = () => {
      const itemName = getMultiSelectItemName()
      const selectedItemsCount = getSelectedCheckBoxes().length - 1
      const labelSpan = document.getElementById(`multi-select-label-${refId.current}`)
      const countSpan = document.getElementById(`multi-select-count-${refId.current}`)
      if (!labelSpan || !countSpan) {
        console.error('labelSpan or countSpan is not found')
        return
      }
      // If "Show all" is selected, update the label to "Show all"
      const showAllItem = items.find(item => item.id === SHOW_ALL_ID)
      if (showAllItem && isSelectedItem(showAllItem)) {
        labelSpan.innerText = showAllItem.name
        countSpan.style.display = 'none'
      } else {
        labelSpan.innerText = `${itemName}`
        countSpan.innerText = `(+${selectedItemsCount})`
        countSpan.style.display = selectedItemsCount > 0 ? 'inline' : 'none'
      }
    }

    const resetMultiSelectItemName = () => {
      const labelSpan = document.getElementById(`multi-select-label-${refId.current}`)
      const countSpan = document.getElementById(`multi-select-count-${refId.current}`)
      if (!labelSpan || !countSpan) {
        console.error('labelSpan or countSpan is not found')
        return
      }
      labelSpan.innerText = getItemName()
      countSpan.style.display = 'none'
    }

    const renderItemName = () => {
      const itemName = getItemName()
      const colon = selectedItem?.addition?.text ? ': ' : ''
      return (
        <div className="flex flex-col">
          <span style={{ color: getLabelColor(itemName) }}>
            {itemName}
            {colon}
          </span>
          {selectedItem?.addition && (
            <span
              style={{
                color: selectedItem.addition.style.color,
                fontSize: selectedItem.addition.style.fontSize,
                fontWeight: selectedItem.addition.style.fontWeight,
                fontStyle: selectedItem.addition.style.fontStyle,
                userSelect: 'none'
              }}>
              {selectedItem.addition.text}
            </span>
          )}
        </div>
      )
    }

    const onSelectItem = async (id: string) => {
      const item = items.find(item => id === item.id)
      if (!item) {
        console.error(`item with id ${id} is not found`)
        return
      }
      if (multiSelect) {
        // if select show all, clear all selected items
        if (item.id === SHOW_ALL_ID) {
          const selected = isSelectedItem(item)
          checkboxesRef.current[item.id].toggle()
          if (selected) {
            // enable all checkboxes
            Object.keys(checkboxesRef.current).forEach(key => {
              if (key !== SHOW_ALL_ID) {
                checkboxesRef.current[key].setDisabled(false)
              }
            })
            const newSelectedItems = getSelectedCheckBoxes().filter(i => i.id !== item.id)
            onMultiSelected && onMultiSelected(newSelectedItems)
            updateMultiSelectItemName()
          } else {
            // disable all checkboxes
            Object.keys(checkboxesRef.current).forEach(key => {
              if (key !== SHOW_ALL_ID) {
                checkboxesRef.current[key].setDisabled(true)
              }
            })
            // if show all, no need to add to selected items
            onMultiSelected && onMultiSelected([])
            updateMultiSelectItemName()
          }
          return
        }

        // other non-show-all items
        // check if disabled
        if (checkboxesRef.current[item.id].getDisabled()) {
          return
        }
        const selected = isSelectedItem(item)
        checkboxesRef.current[item.id].toggle()

        if (selected) {
          // remove item from selected items
          const newSelectedItems = getSelectedCheckBoxes().filter(i => i.id !== item.id)
          onMultiSelected && onMultiSelected(newSelectedItems)
          updateMultiSelectItemName()
        } else {
          // add item to selected items
          const newSelectedItems = [...getSelectedCheckBoxes(), item]
          // deduplicate
          const newSelectedItemsMap = new Map<string, DropDownItem>()
          newSelectedItems.forEach(i => {
            newSelectedItemsMap.set(i.id, i)
          })
          onMultiSelected && onMultiSelected(Array.from(newSelectedItemsMap.values()))
          updateMultiSelectItemName()
        }
        return
      }

      // single select
      if (onPreSelected) {
        refPreSelectedItem.current = item
        onPreSelected(item, refId.current)
      } else {
        setSelectedItem(item)
        if (onSelected) {
          onSelected(item)
        }
      }

      setIsShownDropDown(false)
    }

    const getDropDownClassName = () => {
      return isShownDropDown
        ? 'dropdown-content z-[1] shadow bg-base-100 rounded max-h-[60vh] overflow-y-auto min-w-[max-content] w-full'
        : 'hidden'
    }

    const getDropDownListItemClassName = () => {
      // single line
      // return  'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white whitespace-nowrap text-center',
      // multi line
      return 'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white text-center w-full'
    }

    const hasItem = (): boolean => {
      // no need to show dropdown if there is only one non-label item and selected item is not a label
      if (!selectedItem) {
        if (items.length === 1 && items[0].isLabel) {
          return false
        }
      } else {
        if (items.length === 1) {
          return false
        } else if (items.length === 2 && items[0].isLabel && !selectedItem.isLabel) {
          return false
        }
      }
      return true
    }

    const onClickDropDown = () => {
      if (refMode.current === 'popup') {
        onClick && onClick()
      }
    }

    const onMouseOverDropDown = () => {
      if (refMode.current === 'popup') {
        return
      }
      setIsShownDropDown(hasItem())
      // trigger onMouseOver event if it is defined
      if (onMouseOver) {
        onMouseOver()
      }
    }

    const onConfirmedPreselectedItem = useCallback(
      (data: { id: string }) => {
        if (data.id === refId.current) {
          // console.log('onConfirmedPreselectedItem', data, refPreSelectedItem.current)
          if (refPreSelectedItem.current) {
            setSelectedItem(refPreSelectedItem.current)
            if (onSelected) {
              onSelected(refPreSelectedItem.current)
            }
          }
        }
      },
      [onSelected]
    )

    const getLabelColor = (text: string) => {
      // use labelColor if it is defined
      if (style?.labelColor) {
        return style.labelColor
      }
      // for advanced usage, use colorMapper
      if (colorMapper && colorMapper[text]) {
        return colorMapper[text]
      }
      return 'black'
    }

    // Use useImperativeHandle to expose a method to the parent
    useImperativeHandle(ref, () => ({
      reset: () => {
        setSelectedItem(undefined)
        refPreSelectedItem.current = undefined

        if (multiSelect) {
          // reset all checkboxes
          Object.keys(checkboxesRef.current).forEach(key => {
            checkboxesRef.current[key].reset()
          })
          resetMultiSelectItemName()
        }
      }
    }))

    // if the items are not empty and the first item is not a label, set the first item as the selected item
    useEffect(() => {
      if (value !== undefined) {
        const selectedItemBasedOnValue = items.find(item => item.value === value)
        if (selectedItemBasedOnValue) {
          setSelectedItem(selectedItemBasedOnValue)
          return
        }
      }

      // find default item
      const defaultItem = items.find(item => item.default)
      if (defaultItem) {
        setSelectedItem(defaultItem)
        return
      }

      if (items.length > 0 && !items[0].isLabel) {
        setSelectedItem(items[0])
      }
    }, [items, value])

    useEffect(() => {
      // update selected item if it is changed
      if (selectedItem) {
        const item = items.find(item => selectedItem.id === item.id)
        if (item && selectedItem.name !== item.name) {
          // console.log(`item ${item}`)
          setSelectedItem(item)
        }
      }
    }, [items, selectedItem])

    useEffect(() => {
      Emitter.on(Events.OnConfirmedPreselectedItem, onConfirmedPreselectedItem)
      return () => {
        Emitter.off(Events.OnConfirmedPreselectedItem, onConfirmedPreselectedItem)
      }
    }, [onConfirmedPreselectedItem])

    const zIndex = style?.zIndex ? `z-[${style.zIndex}]` : 'z-1'
    const fontSize = style?.fontSize ? { fontSize: `${style.fontSize}` } : {}
    const fontWeight = style?.fontWeight ? { fontWeight: `${style.fontWeight}` } : {}
    const textAlign = style?.textAlign ? { textAlign: style.textAlign } : {}
    const justifyItems = style?.justifyItems ? style.justifyItems : 'center'
    const cursorPointer = mode === 'popup' ? 'cursor-pointer' : ''
    const border = style?.border ? style.border : ''
    const backgroundColor = style?.backgroundColor ? style.backgroundColor : 'bg-white'

    return (
      <div
        className={`dropdown dropdown-hover w-full ${zIndex} ${cursorPointer}`}
        onClick={onClickDropDown}
        onMouseOver={onMouseOverDropDown}
        style={{ display: 'grid', justifyItems: justifyItems, border: border, backgroundColor: backgroundColor }}>
        <div ref={htmlRef} className={`flex items-center justify-center px-[6px]`}>
          {justifyItems === 'center' && <div className="grow" />}
          <label
            tabIndex={0}
            className={`mentem-dropdown-button mr-4 max-w-[400px] whitespace-normal ${cursorPointer}`}
            style={{
              ...fontSize,
              ...fontWeight
            }}>
            {multiSelect ? renderMultiSelectItemName() : renderItemName()}
          </label>
          <div className="grow" />
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.292893 0.511643C0.653377 0.151159 1.22061 0.12343 1.6129 0.428455L1.70711 0.511643L7 5.80375L12.2929 0.511643C12.6534 0.151159 13.2206 0.12343 13.6129 0.428455L13.7071 0.511643C14.0676 0.872127 14.0953 1.43936 13.7903 1.83165L13.7071 1.92586L7.70711 7.92586C7.34662 8.28634 6.77939 8.31407 6.3871 8.00905L6.29289 7.92586L0.292893 1.92586C-0.0976311 1.53533 -0.0976311 0.902168 0.292893 0.511643Z"
              fill="#51597B"
            />
          </svg>
        </div>
        <div
          style={{
            display: 'grid',
            justifyItems: 'center',
            zIndex: `${style && style.zIndex ? style?.zIndex : 1}`
          }}>
          <ul tabIndex={0} className={getDropDownClassName()}>
            {items
              .filter(item => !item.isLabel && item.id !== selectedItem?.id)
              .map(item => {
                return (
                  <li
                    key={`dropdown-item-${uuidv4()}`}
                    className={getDropDownListItemClassName()}
                    onMouseOver={() => {
                      if (multiSelect && checkboxesRef.current[item.id]) {
                        checkboxesRef.current[item.id].setTheme(CheckboxTheme.White)
                      }
                    }}
                    onMouseLeave={() => {
                      if (multiSelect && checkboxesRef.current[item.id]) {
                        checkboxesRef.current[item.id].setTheme(CheckboxTheme.Normal)
                      }
                    }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onSelectItem(item.id)
                    }}
                    style={{
                      ...fontSize,
                      ...fontWeight,
                      ...textAlign
                    }}>
                    {multiSelect ? (
                      <CheckboxV2
                        ref={checkbox => {
                          if (checkbox) {
                            checkboxesRef.current[item.id] = checkbox
                          }
                        }}
                        label={item.name}
                        checked={isSelectedItem(item)}
                        disabled={isDisabledItem(item)}
                        id={`dropdown-checkbox-${item.id}-${uuidv4()}`}
                        onClick={() => {
                          // disable onClick in checkbox
                        }}
                      />
                    ) : (
                      item.name
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }
)

export default DropDownMenu
