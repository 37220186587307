import { useRef, useState } from 'react'
import { SisArchitectureCourse } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'

const defaultCompletionConfig = `{
  "statuses": {
    "Passed": false
  }
}`

const defaultPassCriteria = `{
  "rules": [
    {
      "operator": "AND", 
      "criteria": [
        {
          "id": "score_threshold",
          "description": "Score at least {{value}}% in all tests",
          "checked": false,
          "valueOperator": "gte",
          "value": 80
        },
        {
          "id": "pass_all_marked_assignments", 
          "description": "Pass all marked assignments", 
          "checked": false
        },
        {
          "id": "complete_all_required_activities", 
          "description": "Complete all required activities", 
          "checked": false
        }
      ]
    }
  ]
}`

function isEmpty(obj: any) {
  return obj === null || obj === undefined || (typeof obj === 'object' && Object.keys(obj).length === 0)
}

interface Props {
  course: SisArchitectureCourse
  statuses: string[]
  onClose: (updated: boolean, updatedCourse?: SisArchitectureCourse) => void
}

export default function ModalSisEditCourse({ course, statuses, onClose }: Props): JSX.Element {
  const [isSaving, setIsSaving] = useState(false)
  const refCourse = useRef<SisArchitectureCourse>(course)
  // console.log('course', course)
  const [completionConfig, setCompletionConfig] = useState(
    !isEmpty(course.completionConfig) ? course.completionConfig : JSON.parse(defaultCompletionConfig)
  )
  const [passCriteria, setPassCriteria] = useState(
    !isEmpty(course.passCriteria) ? course.passCriteria : JSON.parse(defaultPassCriteria)
  )
  const refPassCriteria = useRef<HTMLTextAreaElement>(null)
  const [showPassCriteriaJson, setShowPassCriteriaJson] = useState(false)

  const [renderKey, setRenderKey] = useState(0) // key changes force re-render for defaultValue / defaultChecked

  // update passCriteria from the textarea changes
  const onChangePassCriteriaJson = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const json = JSON.parse(e.target.value)
      setPassCriteria(json)
      setRenderKey(k => k + 1) // increment key to re-render with new default values
    } catch (error) {
      // silently fail or handle error as needed
      console.error('Invalid JSON', error)
    }
  }

  const handleSave = async () => {
    const { displayName, startDate, endDate, censusDate, learningHours } = refCourse.current

    if (!displayName) {
      toastInfo('Please enter a display name')
      return
    }
    if (!startDate) {
      toastInfo('Please enter a start date')
      return
    }
    if (!endDate) {
      toastInfo('Please enter an end date')
      return
    }
    if (!censusDate) {
      toastInfo('Please enter a census date')
      return
    }

    // validate date logic
    // 1. start date should be before end date
    // 2. census date should be before end date and after or equal to start date
    // console.log(startDate)
    // console.log(endDate)
    // console.log(censusDate)
    if (startDate.getTime() >= endDate.getTime()) {
      toastInfo('Start date should be before end date')
      return
    }

    if (censusDate.getTime() >= endDate.getTime() || censusDate.getTime() < startDate.getTime()) {
      toastInfo('Census date should be before end date and after or equal to start date')
      return
    }

    if (!learningHours) {
      toastInfo('Please enter the learning hours')
      return
    }

    // validate learning hours, should be a number and greater than 0
    if (isNaN(learningHours) || learningHours <= 0) {
      toastInfo('Learning hours should be a number and greater than 0')
      return
    }

    if (isEmpty(completionConfig)) {
      toastInfo('Please enter a valid Completion Config')
      return
    }

    if (isEmpty(passCriteria)) {
      toastInfo('Please enter a valid Pass Criteria')
      return
    }

    try {
      setIsSaving(true)
      const result = await SisAPI.updateCourse(course.id, {
        displayName,
        startDate,
        endDate,
        censusDate,
        learningHours,
        completionConfig,
        passCriteria
      })
      if (result) {
        const updatedCourse = { ...course, ...refCourse.current }
        onClose(true, updatedCourse)
        toastSuccess('Course updated successfully')
      } else {
        toastError('Failed to update course')
      }
    } catch (error) {
      console.error(error)
      toastError('An error occurred while updating the course')
    } finally {
      setIsSaving(false)
    }
  }

  const onChangeCompletionConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    // check the id and checked status, then update the completionConfig
    console.log('onChangeCompletionConfig', e.target.id, e.target.checked)
    try {
      const id = e.target.id.split('-')[1]
      const newCompletionConfig = { ...completionConfig }
      newCompletionConfig.statuses[id] = e.target.checked
      console.log(newCompletionConfig)
      setCompletionConfig(newCompletionConfig)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangePassCriteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    // check the id and checked status, then update the passCriteria
    console.log('onChangePassCriteria', e.target.id, e.target.checked)
    try {
      const tmpArr = e.target.id.split('-')
      const id = tmpArr[1]
      const index = parseInt(id)
      const newPassCriteria = { ...passCriteria }
      const ruleIndex = 0 // only one rule for now
      const criterionIndex = index
      if (tmpArr.length === 3 && tmpArr[2] === 'value') {
        newPassCriteria.rules[ruleIndex].criteria[criterionIndex].value = parseInt(e.target.value)
      } else {
        newPassCriteria.rules[ruleIndex].criteria[criterionIndex].checked = e.target.checked
      }
      console.log(newPassCriteria)
      setPassCriteria(newPassCriteria)
      setRenderKey(k => k + 1)

      // sync the textarea with the passCriteria
      if (refPassCriteria.current) {
        refPassCriteria.current.value = JSON.stringify(newPassCriteria, null, 4)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center Overlay">
      <div className="max-h-[90vh] w-auto min-w-[50%] flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center gap-[8px] px-8 w-full overflow-auto">
          <div className="flex flex-row items-center w-full gap-4">
            <label htmlFor="displayName" className="w-1/4 text-right text-[14px]">
              Display Name:
            </label>
            <input
              type="text"
              id="displayName"
              placeholder="Display name"
              className="input-text w-3/4"
              defaultValue={course.displayName}
              onChange={e => {
                refCourse.current.displayName = e.target.value
              }}
            />
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <label htmlFor="startDate" className="w-1/4 text-right text-[14px]">
              Start Date:
            </label>
            <input
              type="date"
              id="startDate"
              className="input-text w-3/4"
              placeholder="Start date"
              defaultValue={course.startDate ? new Date(course.startDate).toISOString().split('T')[0] : ''}
              onChange={e => {
                refCourse.current.startDate = new Date(e.target.value)
              }}
            />
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <label htmlFor="endDate" className="w-1/4 text-right text-[14px]">
              End Date:
            </label>
            <input
              type="date"
              id="endDate"
              className="input-text w-3/4"
              placeholder="End date"
              defaultValue={course.endDate ? new Date(course.endDate).toISOString().split('T')[0] : ''}
              onChange={e => {
                refCourse.current.endDate = new Date(e.target.value)
              }}
            />
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <label htmlFor="censusDate" className="w-1/4 text-right text-[14px]">
              Census Date:
            </label>
            <input
              type="date"
              id="censusDate"
              className="input-text w-3/4"
              placeholder="Census date"
              defaultValue={course.censusDate ? new Date(course.censusDate).toISOString().split('T')[0] : ''}
              onChange={e => {
                refCourse.current.censusDate = new Date(e.target.value)
              }}
            />
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <label htmlFor="learningHours" className="w-1/4 text-right text-[14px]">
              Learning Hours:
            </label>
            <input
              type="number"
              id="learningHours"
              className="input-text w-3/4"
              placeholder="40"
              defaultValue={course.learningHours}
              onChange={e => {
                refCourse.current.learningHours = parseInt(e.target.value)
              }}
            />
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <div className="w-1/4 flex flex-col gap-2">
              <label htmlFor="completionConfig" className="text-right text-[14px]">
                Completion Config:
              </label>
            </div>
            <div className="grid grid-cols-4 gap-2 w-full border border-[#e6e6e6] min-h-[50px] p-[8px]">
              {statuses?.map((status, index) => (
                <div key={index} className="flex flex-row items-center gap-1 cursor-pointer">
                  <input
                    type="checkbox"
                    id={`id-${status}`}
                    defaultChecked={completionConfig?.statuses?.[status]}
                    onChange={onChangeCompletionConfig}
                    className="cursor-pointer"
                  />
                  <label htmlFor={`id-${status}`} className="text-[14px] cursor-pointer">
                    {status}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row items-center w-full gap-4">
            <div className="w-1/4 flex flex-col gap-2">
              <label htmlFor="passCriteria" className="text-right text-[14px]">
                Pass Criteria:
              </label>
              <button
                className="cursor-pointer text-[14px] text-blue-500 underline self-end"
                onClick={() => {
                  // sync the passCriteria with the textarea
                  if (refPassCriteria.current && showPassCriteriaJson) {
                    try {
                      const data = JSON.parse(refPassCriteria.current.value)
                      setPassCriteria(data)
                    } catch (error) {
                      console.error(error)
                    }
                  }
                  setShowPassCriteriaJson(!showPassCriteriaJson)
                }}>
                {showPassCriteriaJson ? 'Hide Json' : 'Show Json'}
              </button>
            </div>
            <div
              className="flex flex-col gap-2 w-full border border-[#e6e6e6] min-h-[50px] p-[8px]"
              style={{ display: showPassCriteriaJson ? 'none' : 'block' }}>
              {passCriteria?.rules?.[0]?.criteria?.map((criterion: any, index: number) => (
                <div key={index} className="flex flex-row items-center gap-2 cursor-pointer">
                  <input
                    key={`crit-chk-${index}-${renderKey}`}
                    type="checkbox"
                    id={`criterion-${index}`}
                    defaultChecked={criterion.checked}
                    onChange={onChangePassCriteria}
                    className="cursor-pointer"
                  />
                  <label htmlFor={`criterion-${index}`} className="text-[14px] cursor-pointer">
                    {criterion.description.split('{{value}}')[0]}
                  </label>
                  {criterion.value !== undefined && (
                    <input
                      key={`crit-val-${index}-${renderKey}`}
                      type="number"
                      id={`criterion-${index}-value`}
                      className="input-text"
                      style={{ width: '70px', height: '20px' }}
                      placeholder="0"
                      defaultValue={criterion.value}
                      onChange={onChangePassCriteria}
                    />
                  )}
                  <label htmlFor={`criterion-${index}`} className="text-[14px] cursor-pointer">
                    {criterion.description.split('{{value}}')[1]}
                  </label>
                </div>
              ))}
            </div>
            <textarea
              ref={refPassCriteria}
              defaultValue={JSON.stringify(passCriteria, null, 4) || ''}
              placeholder={defaultPassCriteria}
              onChange={onChangePassCriteriaJson}
              className="input-text w-full"
              style={{
                height: '220px',
                border: '1px solid #D5D5DB',
                borderRadius: '3px',
                padding: '16px 12px',
                color: '#000',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 600,
                lineHeight: '20px',
                fontStyle: 'normal',
                outline: 'none',
                display: showPassCriteriaJson ? 'block' : 'none'
              }}
            />
          </div>
          <Button label="Save" disabled={isSaving} isLoading={isSaving} onClick={handleSave} />
        </div>
      </div>
    </div>
  )
}
