import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import closeSvg from '../images/close2.svg'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'
import Button from '../components/Button'
import DropDownMenu, { DropDownItem, SHOW_ALL_ID } from '../components/DropDownMenu'
import Emitter, { Events } from '../core/emitter'
import DropDownMenuList from '../components/DropDownMenuList'
import { isEmail } from '../utils/stringUtils'
import { SisClient, SisLearner, SisLearnerWithCourse } from '../apis/entities/sis.entity'
import { SisAPI } from '../apis/SisAPI'

const defaultLocation: DropDownItem = {
  id: SHOW_ALL_ID,
  name: 'Location',
  value: undefined,
  isLabel: true
}

const defaultBusinessUnit: DropDownItem = {
  id: SHOW_ALL_ID,
  name: 'Business unit',
  value: undefined,
  isLabel: true
}

const defaultProgram: DropDownItem = {
  id: SHOW_ALL_ID,
  name: 'Select the program for this learner',
  value: undefined,
  isLabel: true
}

const defaultCohort: DropDownItem = {
  id: SHOW_ALL_ID,
  name: 'Select the cohort for this learner',
  value: undefined,
  isLabel: true
}

interface Props {
  learner: SisLearnerWithCourse
  isNew: boolean
  client: SisClient
  onClose: () => void
}

export default function ModalSisAddOrEditLearner({ learner, isNew, client, onClose }: Props): JSX.Element {
  const refLearner = useRef<SisLearner | any>({ ...learner })
  const [isUpdating, setIsUpdating] = useState(false)
  const [businessUnitOptions, setBusinessUnitOptions] = useState<DropDownItem[]>([])
  const [locationOptions, setLocationOptions] = useState<DropDownItem[]>([])
  const [showPopupList, setShowPopupList] = useState(false)
  const [popupListData, setPopupListData] = useState<{ key: string; items: DropDownItem[]; multiSelect: boolean }>({
    key: '',
    items: [],
    multiSelect: false
  })
  const [isReadyForSave, setIsReadyForSave] = useState(false)
  const [hasCourses, setHasCourses] = useState(false)
  const [selectedProgramId, setSelectedProgramId] = useState<string | undefined>(learner.programId)
  const [selectedCohortId, setSelectedCohortId] = useState<string | undefined>(learner.cohortId)
  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>(learner.courseId)
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([])
  const refDropDownCourse = useRef<any>(null)

  const currentColor = useMemo(() => (isReadyForSave ? '#000000' : '#787676'), [isReadyForSave])

  const defaultCourse: DropDownItem = useMemo(() => {
    return {
      id: '0',
      name: `Select the course${isNew ? '(s)' : ''} for this learner`,
      value: undefined,
      isLabel: true
    }
  }, [isNew])

  const programOptions: DropDownItem[] = useMemo(() => {
    const options = client.programs.map((item, _) => ({
      id: item.id,
      name: item.name,
      value: item.id,
      default: item.id === selectedProgramId
    }))
    return [defaultProgram, ...options]
  }, [client.programs, selectedProgramId])

  const cohortOptions: DropDownItem[] = useMemo(() => {
    // reset selected course ids
    refDropDownCourse.current?.reset()
    const options =
      client.programs
        .find(program => program.id === selectedProgramId)
        ?.cohorts.map((item, _) => ({
          id: item.id,
          name: item.name,
          value: item.id,
          default: item.id === selectedCohortId
        })) || []
    return [defaultCohort, ...options]
  }, [client.programs, selectedProgramId, selectedCohortId])

  const courseOptions: DropDownItem[] = useMemo(() => {
    const options =
      client.programs
        .flatMap(program => program.cohorts)
        .find(cohort => cohort.id === selectedCohortId)
        ?.courses.map((item, _) => ({
          id: item.id,
          name: item.name,
          value: item.id,
          default: item.id === selectedCourseId
        })) || []
    return [defaultCourse, ...options]
  }, [client.programs, defaultCourse, selectedCohortId, selectedCourseId])

  const validate = useCallback(() => {
    let isValid = true
    // all fields are required
    if (
      !refLearner.current.firstName ||
      !refLearner.current.lastName ||
      !refLearner.current.email ||
      !refLearner.current.managerEmail ||
      !refLearner.current.managerFirstName ||
      !refLearner.current.businessUnit ||
      !refLearner.current.location
    ) {
      isValid = false
    }

    if (hasCourses) {
      if (isNew) {
        if (!selectedProgramId || !selectedCohortId || selectedCourseIds.length === 0) {
          isValid = false
        }
      } else {
        if (!selectedProgramId || !selectedCohortId || !selectedCourseId) {
          isValid = false
        }
      }
    }

    setIsReadyForSave(isValid)
  }, [hasCourses, isNew, selectedCohortId, selectedCourseId, selectedCourseIds.length, selectedProgramId])

  const validatePayload = (body: any): boolean => {
    if (!body.firstName || !body.lastName || !body.email || !body.managerFirstName || !body.managerEmail) {
      toastInfo('Please fill all fields')
      return false
    }
    // business unit and location are required
    if (!body.businessUnit || !body.location) {
      toastInfo('Please select business unit and location')
      return false
    }

    // vlidate emails
    if (!isEmail(body.email)) {
      toastInfo('Invalid business email')
      return false
    }

    if (!isEmail(body.managerEmail)) {
      toastInfo('Invalid manager email')
      return false
    }

    if (!body.clientId) {
      toastInfo('Client ID is required')
      return false
    }

    if (hasCourses) {
      if (isNew) {
        // for new learner, courseIds are required
        if (!body.programId || !body.cohortId || !body.courseIds) {
          toastInfo('Please select program, cohort and course(s)')
          return false
        }
      } else {
        // for existing learner, courseId is required
        if (!body.programId || !body.cohortId || !body.courseId) {
          toastInfo('Please select program, cohort and course')
          return false
        }
      }
    }

    return true
  }

  const addLearner = async (body: any) => {
    try {
      // add clientId to body
      body.clientId = client.id
      if (hasCourses) {
        body.programId = selectedProgramId
        body.cohortId = selectedCohortId
        body.courseIds = selectedCourseIds
      }

      // validate body
      if (validatePayload(body) === false) {
        return
      }

      setIsUpdating(true)
      const addedLearner = await SisAPI.addLearner(body)
      toastSuccess('Learner added')
      Emitter.emit(Events.OnAddedLearner, { learner: addedLearner })
      onClose()
    } catch (error) {
      console.error(error)
      toastError('Failed to add learner')
    } finally {
      setIsUpdating(false)
    }
  }

  const updateLearner = async (body: any) => {
    try {
      // validate body
      body.clientId = client.id

      if (selectedProgramId && selectedCohortId && selectedCourseId) {
        body.programId = selectedProgramId
        body.cohortId = selectedCohortId
        body.courseId = selectedCourseId
      }

      if (validatePayload(body) === false) {
        return
      }

      // only patch the fields that are required
      const updatedFields: any = {
        firstName: body.firstName,
        lastName: body.lastName,
        email: body.email,
        managerFirstName: body.managerFirstName,
        managerEmail: body.managerEmail,
        businessUnit: body.businessUnit,
        location: body.location,
        courseId: selectedCourseId
      }

      setIsUpdating(true)
      const updatedLearner = await SisAPI.updateLearner(learner.id, updatedFields)
      toastSuccess('Learner updated')
      Emitter.emit(Events.OnUpdatedLearner, { learner: updatedLearner })
      onClose()
    } catch (error) {
      console.error(error)
      toastError('Failed to update learner')
    } finally {
      setIsUpdating(false)
    }
  }

  const onClickSaveOrConfirm = async () => {
    if (isNew) {
      await addLearner(refLearner.current)
    } else {
      await updateLearner(refLearner.current)
    }
  }

  useEffect(() => {
    if (!client.settings) return
    const businessUnits: any[] = client.settings.businessUnits || []
    const locations: any[] = client.settings.locations || []
    // console.log(client)

    // init Dropdown options
    setBusinessUnitOptions([
      defaultBusinessUnit,
      ...businessUnits.map((item, index) => ({
        id: index.toString(),
        name: item,
        value: item,
        default: item === learner.businessUnit
      }))
    ])

    setLocationOptions([
      defaultLocation,
      ...locations.map((item, index) => ({
        id: index.toString(),
        name: item,
        value: item,
        default: item === learner.location
      }))
    ])

    // check has courses in client
    const hasCourses = client.programs.flatMap(program => program.cohorts).some(cohort => cohort.courses.length > 0)
    setHasCourses(hasCourses)
  }, [client, client.settings, learner.businessUnit, learner.location, selectedProgramId])

  useEffect(() => {
    // validate on mount
    validate()
  }, [validate])

  return (
    <>
      <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
        <div className="max-h-[90%] max-w-3/4 w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
          <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
          <div className="flex flex-col items-center gap-[12px] px-16 overflow-auto w-full">
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              className="input-text"
              defaultValue={learner.firstName}
              onChange={e => {
                refLearner.current.firstName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              className="input-text"
              defaultValue={learner.lastName}
              onChange={e => {
                refLearner.current.lastName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="email"
              id="email"
              placeholder="Business email"
              className="input-text"
              defaultValue={learner.email}
              onChange={e => {
                refLearner.current.email = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="email"
              id="managerEmail"
              placeholder="Direct Manager's email"
              className="input-text"
              defaultValue={learner.managerEmail}
              onChange={e => {
                refLearner.current.managerEmail = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="text"
              id="managerFirstName"
              placeholder="Manager's first name"
              className="input-text"
              defaultValue={learner.managerFirstName}
              onChange={e => {
                refLearner.current.managerFirstName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <DropDownMenu
              items={businessUnitOptions}
              style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
              mode="popup" // check DropDownMenuList
              onClick={() => {
                setPopupListData({
                  key: 'businessUnit',
                  items: businessUnitOptions,
                  multiSelect: false
                })
                setShowPopupList(true)
              }}
            />
            <DropDownMenu
              items={locationOptions}
              style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
              mode="popup" // check DropDownMenuList
              onClick={() => {
                setPopupListData({
                  key: 'location',
                  items: locationOptions,
                  multiSelect: false
                })
                setShowPopupList(true)
              }}
            />
            {hasCourses && (
              <>
                <DropDownMenu
                  items={programOptions}
                  style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
                  onSelected={item => {
                    setSelectedProgramId(item.id)
                  }}
                  // mode="popup" // check DropDownMenuList
                  // onClick={() => {
                  //   setPopupListData({
                  //     key: 'program',
                  //     items: programOptions,
                  //     multiSelect: false
                  //   })
                  //   setShowPopupList(true)
                  // }}
                />
                <DropDownMenu
                  items={cohortOptions}
                  style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
                  onSelected={item => {
                    setSelectedCohortId(item.id)
                  }}
                  // mode="popup" // check DropDownMenuList
                  // onClick={() => {
                  //   setPopupListData({
                  //     key: 'cohort',
                  //     items: cohortOptions,
                  //     multiSelect: false
                  //   })
                  //   setShowPopupList(true)
                  // }}
                />
                <DropDownMenu
                  ref={refDropDownCourse}
                  items={courseOptions}
                  multiSelect={isNew ? true : false}
                  style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
                  onMultiSelected={items => {
                    console.log('selected courses', items)
                    setSelectedCourseIds(items.map(item => item.id))
                  }}
                  onSelected={item => {
                    setSelectedCourseId(item.id)
                  }}
                  // mode="popup" // check DropDownMenuList
                  // onClick={() => {
                  //   setPopupListData({
                  //     key: 'course',
                  //     items: courseOptions,
                  //     multiSelect: true
                  //   })
                  //   setShowPopupList(true)
                  // }}
                />
              </>
            )}
            <div className="h-[50px]" />
            <Button
              label={isNew ? 'Save' : 'Confirm'}
              disabled={isUpdating || !isReadyForSave}
              isLoading={isUpdating}
              onClick={onClickSaveOrConfirm}
            />
          </div>
        </div>
      </div>
      {showPopupList && (
        <DropDownMenuList
          data={popupListData}
          onClose={() => setShowPopupList(false)}
          onSelected={(key, item) => {
            // console.log('selected item', key, item)
            if (key === 'businessUnit' && businessUnitOptions.includes(item)) {
              refLearner.current.businessUnit = item.value
              // update selected item for dropdown by setting the selected item
              setBusinessUnitOptions(
                businessUnitOptions.map(option => {
                  if (option.id === item.id) {
                    return { ...option, default: true }
                  } else {
                    return { ...option, default: false }
                  }
                })
              )
            } else if (key === 'location' && locationOptions.includes(item)) {
              refLearner.current.location = item.value
              // update selected item for dropdown by setting the selected item
              setLocationOptions(
                locationOptions.map(option => {
                  if (option.id === item.id) {
                    return { ...option, default: true }
                  } else {
                    return { ...option, default: false }
                  }
                })
              )
            } else if (key === 'program' && programOptions.includes(item)) {
              setSelectedProgramId(item.id)
            } else if (key === 'cohort' && cohortOptions.includes(item)) {
              setSelectedCohortId(item.id)
            }
            setShowPopupList(false)
          }}
          onMultiSelected={(key, items) => {
            if (key === 'course') {
              console.log('selected courses', items)
              setSelectedCourseIds(items.map(item => item.id))
            }
          }}
        />
      )}
    </>
  )
}
