import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu'
import { useCallback } from 'react'
import { ItemPosition, useListContext } from './ListContext'

export default function DropDownContent({ position, index }: { position: ItemPosition; index: number }) {
  const { reorderItem, getListLength } = useListContext()

  const isMoveUpDisabled = position === 'first' || position === 'only'
  const isMoveDownDisabled = position === 'last' || position === 'only'

  const moveToTop = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: 0,
      closestEdgeOfTarget: null
    })
  }, [index, reorderItem])

  const moveUp = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: index - 1,
      closestEdgeOfTarget: null
    })
  }, [index, reorderItem])

  const moveDown = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: index + 1,
      closestEdgeOfTarget: null
    })
  }, [index, reorderItem])

  const moveToBottom = useCallback(() => {
    reorderItem({
      startIndex: index,
      indexOfTarget: getListLength() - 1,
      closestEdgeOfTarget: null
    })
  }, [index, getListLength, reorderItem])

  return (
    <DropdownItemGroup>
      <DropdownItem onClick={moveToTop} isDisabled={isMoveUpDisabled}>
        Move to top
      </DropdownItem>
      <DropdownItem onClick={moveUp} isDisabled={isMoveUpDisabled}>
        Move up
      </DropdownItem>
      <DropdownItem onClick={moveDown} isDisabled={isMoveDownDisabled}>
        Move down
      </DropdownItem>
      <DropdownItem onClick={moveToBottom} isDisabled={isMoveDownDisabled}>
        Move to bottom
      </DropdownItem>
    </DropdownItemGroup>
  )
}
