import { useEffect, useState } from 'react'
import LearnersDetails from './LearnersDetails'
import { SisAPI } from '../apis/SisAPI'
import { toastError } from '../utils/toast'
import LoadingIndicator from '../components/LoadingIndicator'

export default function LearnersDetailsForClient() {
  const [isLoading, setIsLoading] = useState(true)
  const [clientId, setClientId] = useState('')

  const fetchClientData = async () => {
    try {
      setIsLoading(true)
      const response = await SisAPI.getMyClient()
      setClientId(response.clientId)
      setIsLoading(false)
    } catch (error) {
      toastError("Couldn't fetch client data")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchClientData()
  }, [])

  return (
    <>
      {isLoading && (
        <div className="flex w-full h-screen flex-col items-center justify-center bg-white p-0">
          <div className="flex w-full h-full items-center justify-center">
            <LoadingIndicator color={'#000'} />
          </div>
        </div>
      )}
      {!isLoading && <LearnersDetails forIframe={true} myClientId={clientId} />}
    </>
  )
}
