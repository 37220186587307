import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import LoadingIndicator from '../components/LoadingIndicator'
import SidebarLayout from '../components/SidebarLayout'
import sortSvg from '../images/sort.svg'
import sort2Svg from '../images/sort2.svg'
import { Tooltip } from 'react-tooltip'
import {
  alternateStyle,
  Direction,
  DirectionAsc,
  DirectionDesc,
  tooltipStyle,
  tooltipStyleReverse
} from '../utils/TableUtils'
import SearchInput from '../components/SearchInput'
import DropDownMenu, { DropDownItem, SHOW_ALL_ID } from '../components/DropDownMenu'
import { toastError, toastSuccess } from '../utils/toast'
import { useAuth } from '../context/AuthProvider'
import Checkbox, { CheckboxSize, CheckboxTheme } from '../components/Checkbox'
import { useParams } from 'react-router-dom'
import Emitter, { Events } from '../core/emitter'
import Button from '../components/Button'
import { v4 as uuidv4 } from 'uuid'
import ReactLoading from 'react-loading'
import { SisAPI } from '../apis/SisAPI'
import { ClientSettings, LearnerStatus, SisClient, SisLearnerWithCourse } from '../apis/entities/sis.entity'
import ModalSisAddOrEditLearner from '../modals/ModalSisAddOrEditLearner'
import ModalSisHistory from '../modals/ModalSisHistory'
import ModalSisEditNote from '../modals/ModalSisEditNote'
import ModalSisEditStatus from '../modals/ModalSisEditStatus'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import React from 'react'
import ModalSisConfirmation from '../modals/ModalSisConfirmation'

const showAllItems = { id: SHOW_ALL_ID, name: 'Show all', value: undefined }

const defaultProgram: DropDownItem = {
  id: '0',
  name: 'Program',
  value: undefined,
  isLabel: true
}

const defaultCohort: DropDownItem = {
  id: '0',
  name: 'Cohort',
  value: undefined,
  isLabel: true
}

const defaultLocation: DropDownItem = {
  id: '0',
  name: 'Location',
  value: undefined,
  isLabel: true
}

const defaultBusinessUnit: DropDownItem = {
  id: '0',
  name: 'Business unit',
  value: undefined,
  isLabel: true
}

const defaultLearnerStatus: DropDownItem = {
  id: '0',
  name: 'Status',
  value: undefined,
  isLabel: true
}

interface Props {
  forIframe: boolean
  myClientId?: string
}

export default function LearnersDetails({ forIframe, myClientId }: Props) {
  // console.log(`forIframe: ${forIframe}`)
  const { isSuperAdmin, isOrgAdmin, isMixedAdmin } = useAuth()
  const [client, setClient] = useState<SisClient | undefined>(undefined)
  const [tableHeaders, setTableHeaders] = useState<any[]>([])
  const refAllLearners = useRef<SisLearnerWithCourse[] | undefined>([]) // flatten learners without children
  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const refSearchTextInput = useRef<string>('')
  const [searchTextValue, setSearchTextValue] = useState('')
  const refDropDownProgram = useRef<any>(null)
  const refDropDownCohort = useRef<any>(null)
  const refDropDownLocation = useRef<any>(null)
  const refDropDownBusinessUnit = useRef<any>(null)
  const refDropDownLearnerStatus = useRef<any>(null)
  const { clientId: queryClientId } = useParams()
  const clientId = myClientId || queryClientId

  const [sortConfig, setSortConfig] = useState<{
    key: string
    direction: Direction
  } | null>(null)
  const [order, setOrder] = useState<{ [key: string]: Direction }>({})

  const [selectedLearner, setSelectedLearner] = useState<SisLearnerWithCourse | undefined>(undefined)
  // dropdown items
  const [programs, setPrograms] = useState<DropDownItem[]>([defaultProgram, showAllItems])
  const [cohorts, setCohorts] = useState<DropDownItem[]>([defaultCohort, showAllItems])
  const [locations, setLocations] = useState<DropDownItem[]>([defaultLocation, showAllItems])
  const [businessUnits, setBusinessUnits] = useState<DropDownItem[]>([defaultBusinessUnit, showAllItems])
  const [learnerStatuses, setLearnerStatuses] = useState<DropDownItem[]>([defaultLearnerStatus, showAllItems])
  const [selectedPrograms, setSelectedPrograms] = useState<string[] | undefined>(undefined)
  const [selectedCohorts, setSelectedCohorts] = useState<string[] | undefined>(undefined)
  const [selectedLocations, setSelectedLocations] = useState<string[] | undefined>(undefined)
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[] | undefined>(undefined)
  const [selectedLearnerStatuses, setSelectedLearnerStatuses] = useState<string[] | undefined>(undefined)

  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [checkboxSelectAll, setCheckboxSelectAll] = useState(false)
  const [checkboxShowCompanyInformation, setCheckboxShowCompanyInformation] = useState(true)
  const [checkboxShowMostRecent, setCheckboxShowMostRecent] = useState(true)

  const refCompanyInformationKeys = useRef<string[]>([]) // by filter = company

  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [isSyncingInsights, setIsSyncingInsights] = useState<boolean>(false)
  const [isSyncingEoi, setIsSyncingEoi] = useState<boolean>(false)
  const [isSyncingCanvas, setIsSyncingCanvas] = useState<boolean>(false)
  const refTableDiv = useRef<HTMLDivElement | null>(null)

  // for confirmation dialog
  const refConfirmationPayload = useRef<{ id: string; body: any } | undefined>(undefined)
  const [confirmationData, setConfirmationData] = useState<{ learner: SisLearnerWithCourse; newStatus: string }>({
    learner: {} as SisLearnerWithCourse,
    newStatus: ''
  })
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false)
  const refPreSelectedDropdownId = useRef<string | undefined>(undefined)
  const [isNewAdd, setIsNewAdd] = useState<boolean>(false)
  const [isReloading, setIsReloading] = useState<boolean>(false)

  // for edit record modal
  const [showEditLearner, setShowEditLearner] = useState<boolean>(false)
  const refSearchInput = useRef<any>(null)

  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [showNote, setShowNote] = useState<boolean>(false)
  const [showEditStatus, setShowEditStatus] = useState<boolean>(false)
  const [learners, setLearners] = useState<SisLearnerWithCourse[]>([])
  const [expandedLearnerMap, setExpandedLearnerMap] = useState<{ [key: string]: boolean }>({})

  const dummyRows = 4 // for better UX, always add x dummy rows for dropdown menu

  const hasWritePermission = useMemo(() => {
    return isSuperAdmin() || isOrgAdmin() || isMixedAdmin()
  }, [isMixedAdmin, isOrgAdmin, isSuperAdmin])

  const hasFilter = useMemo(() => {
    return selectedLocations || selectedBusinessUnits || selectedLearnerStatuses || selectedPrograms || selectedCohorts
  }, [selectedLocations, selectedBusinessUnits, selectedLearnerStatuses, selectedPrograms, selectedCohorts])

  const hasFilterOrSearch = useMemo(() => {
    return hasFilter || searchTextValue
  }, [hasFilter, searchTextValue])

  const preCheckedIdsForStatus = useMemo(() => {
    return checkedIds.map(id => {
      const parts = id.split('#')
      return Number(parts[1])
    })
  }, [checkedIds])

  const canEditStatus = useMemo(() => {
    const editable = client?.settings?.adminHub?.tableHeaders?.find(header => header.key === 'status')?.editable
    return editable || false
  }, [client?.settings?.adminHub?.tableHeaders])

  const updateDropDownItemsByClient = (client: SisClient) => {
    // update programs
    const programs = client.programs.map(program => {
      return { id: program.id, name: program.name, value: program.id }
    })
    setPrograms([defaultProgram, ...programs, showAllItems])

    // update cohorts
    const cohorts: DropDownItem[] = []
    client.programs.forEach(program => {
      program.cohorts.forEach(cohort => {
        cohorts.push({ id: cohort.id, name: cohort.name, value: cohort.id })
      })
    })
    setCohorts([defaultCohort, ...cohorts, showAllItems])

    // update locations
    if (client.settings?.locations) {
      const locations = client.settings.locations.map((location: string) => {
        return { id: location, name: location, value: location }
      })
      setLocations([defaultLocation, ...locations, showAllItems])
    }

    // update business units
    if (client.settings?.businessUnits) {
      const businessUnits = client.settings.businessUnits.map((businessUnit: string) => {
        return { id: businessUnit, name: businessUnit, value: businessUnit }
      })
      setBusinessUnits([defaultBusinessUnit, ...businessUnits, showAllItems])
    }
  }

  const updateDropDownItemsForLearnerStatus = (settings: ClientSettings) => {
    const statuses = settings?.adminHub?.tableHeaders?.find(header => header.key === 'status')?.options || []
    const applicationStatuses = statuses.map((status: string, index: number) => {
      return {
        id: `${index + 1}`,
        name: status,
        value: status
      }
    })
    setLearnerStatuses([defaultLearnerStatus, ...applicationStatuses, showAllItems])
  }

  const onSelectedProgram = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedPrograms([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedPrograms(undefined)
    }
  }

  const onMultiSelectedProgram = (items: DropDownItem[]) => {
    if (items.length === 0) {
      setSelectedPrograms(undefined)
    } else {
      const values = items.map(item => item.value)
      setSelectedPrograms(values)
    }
  }

  const onSelectedCohort = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedCohorts([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedCohorts(undefined)
    }
  }

  const onMultiSelectedCohort = (items: DropDownItem[]) => {
    if (items.length === 0) {
      setSelectedCohorts(undefined)
    } else {
      const values = items.map(item => item.value)
      setSelectedCohorts(values)
    }
  }

  const onSelectedLocation = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedLocations([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedLocations(undefined)
    }
  }

  const onMultiSelectedLocation = (items: DropDownItem[]) => {
    if (items.length === 0) {
      setSelectedLocations(undefined)
    } else {
      const values = items.map(item => item.value)
      setSelectedLocations(values)
    }
  }

  const onSelectedBusinessUnit = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedBusinessUnits([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedBusinessUnits(undefined)
    }
  }

  const onMultiSelectedBusinessUnit = (items: DropDownItem[]) => {
    if (items.length === 0) {
      setSelectedBusinessUnits(undefined)
    } else {
      const values = items.map(item => item.value)
      setSelectedBusinessUnits(values)
    }
  }

  const onSelectedLearnerStatus = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedLearnerStatuses([item.value])
    } else if (item.id === SHOW_ALL_ID) {
      setSelectedLearnerStatuses(undefined)
    }
  }

  const onMultiSelectedLearnerStatus = (items: DropDownItem[]) => {
    if (items.length === 0) {
      setSelectedLearnerStatuses(undefined)
    } else {
      const values = items.map(item => item.value)
      setSelectedLearnerStatuses(values)
    }
  }

  const onInputChangedSearch = (value: string) => {
    refSearchTextInput.current = value
  }

  const onEnterSearch = async () => {
    // filter by search text (name or email)
    const searchText = refSearchTextInput.current.trim().toLowerCase()
    setSearchTextValue(searchText)
    if (!searchText || !refAllLearners.current) {
      // reset
      setLearners(refAllLearners.current || [])
      return
    }
    setIsSearching(true)
    const filteredItems = refAllLearners.current.filter(item => {
      return (
        item.firstName?.toLowerCase().includes(searchText) ||
        item.lastName?.toLowerCase().includes(searchText) ||
        item.email?.toLowerCase().includes(searchText)
      )
    })

    setLearners(filteredItems)
    setIsSearching(false)
  }

  const getTooptipStyle = (key: string): any => {
    tableHeaders.forEach(header => {
      if (header.key === key) {
        return order[key] === DirectionAsc ? tooltipStyleReverse : tooltipStyle
      }
    })
  }

  const getSortingText = (key: string): string => {
    let text = ''
    tableHeaders.forEach(header => {
      if (header.key === key) {
        text = order[key] === DirectionAsc ? 'Sort Z to A' : 'Sort A to Z'
      }
    })
    return text
  }

  const onClickSort = (key: string) => {
    let ascending = true
    if (sortConfig && sortConfig.key === key && sortConfig.direction === DirectionAsc) {
      ascending = false
    }
    setOrder({ [key]: ascending ? DirectionAsc : DirectionDesc })
    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  // it could be flattened or nested children depends on the filter, if showing all, it will be nested, otherwise, it will be flattened
  const sortedLearners = useMemo(() => {
    let sortableItems: SisLearnerWithCourse[] = []
    let tmpLearners = learners?.length ? [...learners] : []

    // group by parent and children if showing all
    if (hasFilterOrSearch) {
      sortableItems = tmpLearners
    } else {
      for (const item of tmpLearners) {
        // popualate default status if not exist
        if (!item.status && item.courseId) {
          item.status = LearnerStatus.Confirmed
        }

        const learner = sortableItems.find(l => l.id === item.id)
        if (learner) {
          if (learner.children) {
            learner.children.push(item)
          } else {
            learner.children = [item]
          }
        } else {
          sortableItems.push({
            ...item,
            children: []
          })
        }
      }
    }

    // filter by program
    if (selectedPrograms) {
      sortableItems = sortableItems.filter(item => selectedPrograms.includes(item.programId || ''))
    }

    // filter by cohort
    if (selectedCohorts) {
      sortableItems = sortableItems.filter(item => selectedCohorts.includes(item.cohortId || ''))
    }

    // filter by location
    if (selectedLocations) {
      sortableItems = sortableItems.filter(item => selectedLocations.includes(item.location || ''))
    }

    // filter by business unit
    if (selectedBusinessUnits) {
      sortableItems = sortableItems.filter(item => selectedBusinessUnits.includes(item.businessUnit || ''))
    }

    // filter by application status
    if (selectedLearnerStatuses) {
      // console.log(selectedLearnerStatuses)
      sortableItems = sortableItems.filter(item => selectedLearnerStatuses.includes(item.status || ''))
    }

    if (sortConfig !== null) {
      sortableItems.sort((a: any, b: any) => {
        const valueA = a[sortConfig.key]?.toString().toLowerCase()
        const valueB = b[sortConfig.key]?.toString().toLowerCase()

        // Handle undefined values
        if (!valueA && !valueB) {
          return 0
        }
        if (!valueA) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }
        if (!valueB) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }

        // Compare normalized (case-insensitive) values
        if (valueA < valueB) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }
        if (valueA > valueB) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }

        return 0
      })
    }

    // always add 3 dummy rows for dropdown menu UX for the last row
    for (let i = 0; i < dummyRows; i++) {
      sortableItems.push({} as any)
    }

    // for any change, reset select all checkbox
    setCheckboxSelectAll(false)

    return sortableItems
  }, [
    learners,
    hasFilterOrSearch,
    selectedPrograms,
    selectedCohorts,
    selectedLocations,
    selectedBusinessUnits,
    selectedLearnerStatuses,
    sortConfig
  ])

  const numFilteredLearners = useMemo(() => {
    if (hasFilterOrSearch) {
      // group by learner id
      const groupedLearners: { [key: string]: SisLearnerWithCourse } = {}
      for (const item of sortedLearners) {
        if (item.id) {
          groupedLearners[item.id] = item
        }
      }
      return Object.keys(groupedLearners).length
    }
    return sortedLearners.length - dummyRows
  }, [sortedLearners, hasFilterOrSearch])

  const numFilteredRecords = useMemo(() => {
    if (hasFilterOrSearch) {
      return sortedLearners.length - dummyRows
    } else {
      return learners?.length || 0
    }
  }, [hasFilterOrSearch, sortedLearners, learners])

  const getDateTimeString = (date: Date) => {
    if (!date) {
      return ''
    }
    // convert date to format: 6 Feb 2024 09:16
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  // always hard code and frontend logic
  const getDisplayValue = useCallback((learner: SisLearnerWithCourse, header: any) => {
    const key = header.key
    switch (key) {
      default:
        let value = (learner as any)[key]

        // override values
        if (key === 'status' && learner.clientId) {
          if (value === '' || value === undefined || value === null) {
            return LearnerStatus.Confirmed
          } else if (value === LearnerStatus.Failed || value === LearnerStatus.Terminated) {
            return <span className="text-[#ff0000]">{value}</span>
          }
        }

        // chage color
        if (value === 'N' && key !== 'firstName' && key !== 'lastName') {
          return <span className="text-[#ff0000]">{value}</span>
        }

        // check is date format
        if (header.type === 'date') {
          if (value) {
            const date = new Date(value)
            return getDateTimeString(date)
          }
        }

        // check if value is an array, join with comma
        if (Array.isArray(value)) {
          value = value.join(', ')
          return <div className="whitespace-normal overflow-y-auto">{value}</div>
        }

        if (value === undefined || value === null) {
          // try to find in raw data
          if (learner.raw && learner.raw[key]) {
            value = learner.raw[key]
          }
        }

        return (
          <div
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden'
            }}>
            {value}
          </div>
        )
    }
  }, [])

  const requireConfirmation = (item: DropDownItem, key: string): boolean => {
    return key === 'status' && item.value === LearnerStatus.Terminated
  }

  const onPreSelectedStatus = useCallback(async (learner: SisLearnerWithCourse, item: DropDownItem, key: string) => {
    // console.log(`onPreSelectedStatus: ${learner.id}, ${item.value}, ${key}`)
    const body = { [key]: item.value }

    // if key is `learnerStatus` and the value is Invited or Rejected, show confirmation dialog
    if (requireConfirmation(item, key)) {
      refConfirmationPayload.current = { id: learner.id!, body }
      setConfirmationData({ learner: learner, newStatus: item.value })
      setShowConfirmation(true)
    } else {
      // directly confirm the preselected item
      Emitter.emit(Events.OnConfirmedPreselectedItem, {
        id: refPreSelectedDropdownId.current
      })
      // reset
      refPreSelectedDropdownId.current = undefined
    }
  }, [])

  const updateLearner = useCallback(
    async (courseId: string, learnerId: string, body: any): Promise<boolean> => {
      try {
        if (!clientId) {
          toastError('Client ID is not found')
          return false
        }
        if (!courseId) {
          toastError('Course ID is not found')
          return false
        }
        const updatedItem = await SisAPI.updateLearnerWithCourse(clientId, courseId, learnerId, body)
        console.log(updatedItem)
        if (!updatedItem) {
          toastError('Failed to update the learner')
          return false
        } else {
          // find the index and update the item and re-render
          toastSuccess('Learner has been updated', 3000)
          // update refAllLearners
          if (refAllLearners.current) {
            // console.log(refAllLearners.current)
            const index = refAllLearners.current.findIndex(
              learner => learner.id === updatedItem.id && learner.courseId === updatedItem.courseId
            )
            // console.log(`updateLearner: ${index}`)
            if (index !== undefined && index !== -1) {
              refAllLearners.current[index] = {
                ...refAllLearners.current[index],
                ...updatedItem
              }
            }
            // re-render
            setLearners([...refAllLearners.current])
          }
        }
        return true
      } catch (error) {
        console.error(error)
        toastError('Failed to update the learner')
        return false
      }
    },
    [clientId]
  )

  const onSelectedStatus = useCallback(
    async (result: SisLearnerWithCourse, item: DropDownItem, key: string) => {
      if (!requireConfirmation(item, key)) {
        // update directly
        // console.log(`onSelectedStatus: ${result.id}, ${item.value}, ${key}`)
        const body = { [key]: item.value }
        await updateLearner(result.courseId || '', result.id || '', body)
      }
    },
    [updateLearner]
  )

  const renderFirstName = useCallback(
    (item: any, isChildren: boolean) => {
      const uid = `${item.id}#${item.courseToLearnerId}`
      return (
        <div
          style={{ height: '100%', width: '100%' }}
          className="flex flex-row items-center justify-between w-full px-[6px]">
          {/* leading tab for children */}
          {isChildren && <div className="w-[24px]" />}

          <Checkbox
            id={`candidate-checkbox-${uid}`}
            checked={checkedIds.includes(uid)}
            onChange={checked => {
              if (checked) {
                setCheckedIds([...checkedIds, uid])
                // check if all items are selected
                if (checkedIds.length + 1 === learners?.length) {
                  setCheckboxSelectAll(true)
                }
              } else {
                setCheckedIds(checkedIds.filter(id => id !== uid))
                // reset select all checkbox
                if (checkboxSelectAll) {
                  setCheckboxSelectAll(false)
                }
              }
            }}
            size={CheckboxSize.Medium}
            theme={CheckboxTheme.Normal}
          />

          <div className="w-[6px]" />

          <span
            className="grow text-left"
            onClick={() => {
              setSelectedLearner(item)
            }}>
            {item.firstName}
          </span>

          <div
            className="cursor-pointer hover:opacity-[0.8]"
            onClick={() => {
              setIsNewAdd(false)
              setSelectedLearner(item)
              setShowEditLearner(true)
            }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="Style"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9333 4.89324L11.1066 3.06657C10.6131 2.60301 9.8505 2.58294 9.33329 3.01991L3.33329 9.01991C3.1178 9.23722 2.98363 9.52204 2.95329 9.82657L2.66662 12.6066L2.66662 12.6066C2.64844 12.8043 2.71931 12.9998 2.85996 13.1399H2.85996C2.98577 13.2647 3.15609 13.3343 3.33329 13.3332H3.39329L6.17329 13.0799C6.47782 13.0496 6.76265 12.9154 6.97996 12.6999L12.98 6.69991C13.4648 6.18768 13.4439 5.37975 12.9333 4.89324V4.89324ZM6.05444 11.7465L4.05444 11.9332L4.23444 9.93322L8.00111 6.21322L9.80111 8.01322L6.05444 11.7465ZM10.6666 7.11991L8.87996 5.33324L10.18 3.99991L12 5.81991L10.6666 7.11991Z"
                fill="#BA61FF"
              />
            </svg>
          </div>

          {item.children && item.children.length > 0 && (
            <>
              <div className="w-[6px]" />
              <span className="text-[#32374E] text-[14px] font-[600]">{item.children.length}</span>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setExpandedLearnerMap({
                    ...expandedLearnerMap,
                    [item.id]: !expandedLearnerMap[item.id]
                  })
                }}>
                {expandedLearnerMap[item.id] ? (
                  <MinusIcon style={{ color: '#121419', fontSize: 16 }} />
                ) : (
                  <PlusIcon style={{ color: '#121419', fontSize: 16 }} />
                )}
              </div>
            </>
          )}
          {/* tailing padding */}
          {isChildren && <div className="w-[12px]" />}
        </div>
      )
    },
    [checkboxSelectAll, checkedIds, expandedLearnerMap, learners?.length]
  )

  const renderNonFirstName = useCallback(
    (item: any, header: any, isChildren: boolean) => {
      return (
        <div
          // className="cursor-pointer"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            display: 'flex'
          }}
          onClick={() => {
            // setSelectedLearner(item)
          }}>
          {header.key !== 'note' && header.key !== 'lastName' && (
            <div className="h-full flex flex-col items-center justify-center">
              {<>{getDisplayValue(item, header)}</>}
            </div>
          )}
          {header.key === 'note' && item.courseId && (
            <div
              className="svg-container"
              onClick={e => {
                setSelectedLearner(item)
                setShowNote(true)
              }}>
              <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  className="svg-path-stroke"
                  d="M5 5H11M5 9H11M5 13H9M1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3Z"
                  stroke="black"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
          {header.key === 'history' && item.courseId && (
            <div
              className="svg-container"
              data-tooltip-id="icon-history"
              onClick={e => {
                e.stopPropagation()
                setSelectedLearner(item)
                setShowHistory(true)
              }}>
              <svg width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.5 0C12.0196 0 14.4359 1.00089 16.2175 2.78249C17.9991 4.56408 19 6.98044 19 9.5C19 12.0196 17.9991 14.4359 16.2175 16.2175C14.4359 17.9991 12.0196 19 9.5 19C6.98044 19 4.56408 17.9991 2.78249 16.2175C1.00089 14.4359 0 12.0196 0 9.5C0 6.98044 1.00089 4.56408 2.78249 2.78249C4.56408 1.00089 6.98044 0 9.5 0ZM9.5 1C7.24566 1 5.08365 1.89553 3.48959 3.48959C1.89553 5.08365 1 7.24566 1 9.5C1 11.7543 1.89553 13.9163 3.48959 15.5104C5.08365 17.1045 7.24566 18 9.5 18C10.6162 18 11.7215 17.7801 12.7528 17.353C13.7841 16.9258 14.7211 16.2997 15.5104 15.5104C16.2997 14.7211 16.9258 13.7841 17.353 12.7528C17.7801 11.7215 18 10.6162 18 9.5C18 7.24566 17.1045 5.08365 15.5104 3.48959C13.9163 1.89553 11.7543 1 9.5 1ZM9 4H10V9.42L14.7 12.13L14.2 13L9 10V4Z"
                  className="svg-path"
                  fill="black"
                />
              </svg>
            </div>
          )}
          {header.key === 'lastName' && (
            <>
              {isChildren && <div className="w-[24px]" />}
              <div className="flex items-center justify-start w-full">
                <span>{item.lastName}</span>
              </div>
            </>
          )}
        </div>
      )
    },
    [getDisplayValue]
  )

  const renderStatus = useCallback(
    (item: any, header: any) => {
      if (header.editable === true) {
        return (
          <DropDownMenu
            items={header.options.map((option: string, index: number) => {
              let addition: any = undefined
              return {
                id: index.toString(),
                name: option === '' ? '-' : `${option}`,
                value: option,
                default: item[header.key] === option,
                addition: addition
              } as DropDownItem
            })}
            value={item[header.key]}
            onSelected={selectedItem => {
              onSelectedStatus(item, selectedItem, header.key)
            }}
            colorMapper={{
              Terminated: '#ff0000',
              Failed: '#ff0000'
            }}
            onPreSelected={
              header.key === 'status'
                ? (selectedItem, dropDownId) => {
                    refPreSelectedDropdownId.current = dropDownId
                    onPreSelectedStatus(item, selectedItem, header.key)
                  }
                : undefined
            }
            id={`dropdown-${item.id}-${item.courseId}-${header.key}`}
            style={{ border: 'none', backgroundColor: 'transparent', fontSize: '14px' }}
          />
        )
      } else {
        return <div>{getDisplayValue(item, header)}</div>
      }
    },
    [getDisplayValue, onPreSelectedStatus, onSelectedStatus]
  )

  const renderRow = useCallback(
    (item: any, isChildren: boolean = false) => {
      // console.log(item)
      const isDummy = !item.id
      const key = isDummy ? `dummy-${uuidv4()}` : `${item.id}-${uuidv4()}`
      return (
        <tr key={key} className={isDummy ? 'dummy' : ''} style={{ height: '52px' }}>
          {tableHeaders.map((header, index: number) => {
            if (isDummy) {
              return (
                <td key={header.key}>
                  <div className="h-[52px]"></div>
                </td>
              )
            }
            return (
              <td className={alternateStyle('td', index % 2 === 0)} key={header.key}>
                {header.key === 'status' && header.options && hasWritePermission && item.courseId ? (
                  renderStatus(item, header)
                ) : (
                  <div className="flex items-center justify-center" style={{ height: '48px', width: '100%' }}>
                    {header.key === 'firstName' && renderFirstName(item, isChildren)}
                    {header.key !== 'firstName' && renderNonFirstName(item, header, isChildren)}
                  </div>
                )}
              </td>
            )
          })}
        </tr>
      )
    },
    [hasWritePermission, renderFirstName, renderNonFirstName, renderStatus, tableHeaders]
  )

  const fetchLearnersSliently = useCallback(async (clientId: string) => {
    const data = await SisAPI.getLearnersByClient(clientId)
    refAllLearners.current = data.learners

    const searchText = refSearchTextInput.current.trim().toLowerCase()
    if (!searchText || !data.learners) {
      setLearners(data.learners)
    } else {
      const filteredItems = data.learners.filter(item => {
        return (
          item.firstName?.toLowerCase().includes(searchText) ||
          item.lastName?.toLowerCase().includes(searchText) ||
          item.email?.toLowerCase().includes(searchText)
        )
      })
      setLearners(filteredItems)
    }
  }, [])

  const onReloadLearners = useCallback(async () => {
    setIsReloading(true)
    await fetchLearnersSliently(clientId || '')
    setIsReloading(false)
  }, [clientId, fetchLearnersSliently])

  const onEditStatus = () => {
    setShowEditStatus(true)
  }

  const fetchLearners = useCallback(async () => {
    try {
      if (!clientId) {
        toastError('Client ID is not found')
        return
      }
      setIsLoading(true)
      const data = await SisAPI.getLearnersByClient(clientId)
      refAllLearners.current = data.learners
      setLearners(data.learners)
      // sort by firstName
      // setSortConfig({ key: 'firstName', direction: DirectionAsc })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [clientId])

  const fetchClient = useCallback(async () => {
    try {
      if (!clientId) {
        toastError('Client ID is not found')
        return
      }
      setIsLoading(true)
      const client = await SisAPI.getClient(clientId)
      // console.log(client)
      updateDropDownItemsByClient(client)
      const filteredTableHeaders = client.settings?.adminHub?.tableHeaders || []
      setClient(client)

      // set filter keys
      const companyInformationKeys = client.settings?.adminHub?.tableHeaders
        .filter(header => header.filter === 'company')
        .map(header => header.key)
      refCompanyInformationKeys.current = companyInformationKeys

      // update dropdown items
      updateDropDownItemsForLearnerStatus(client.settings || {})

      setTableHeaders(filteredTableHeaders)
      await fetchLearners()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [clientId, fetchLearners])

  const onResetFilters = () => {
    // reset input
    refSearchTextInput.current = ''
    setSearchTextValue('')
    refSearchInput.current.clear()
    // reset learners
    const tmpLearners = refAllLearners.current ? [...refAllLearners.current] : []
    setLearners(tmpLearners || [])
    // reset filter dropdowns
    refDropDownProgram.current?.reset()
    refDropDownCohort.current?.reset()
    refDropDownLocation.current?.reset()
    refDropDownBusinessUnit.current?.reset()
    refDropDownLearnerStatus.current?.reset()

    setSelectedPrograms(undefined)
    setSelectedCohorts(undefined)
    setSelectedLocations(undefined)
    setSelectedBusinessUnits(undefined)
    setSelectedLearnerStatuses(undefined)
  }

  const onAddNew = async () => {
    if (!client) {
      toastError('Program is not found')
      return
    }
    setIsNewAdd(true)
    // dummy selected report
    setSelectedLearner({} as any)
    setShowEditLearner(true)
  }

  const onSyncCanvas = async () => {
    try {
      setIsSyncingCanvas(true)
      if (client) {
        // sync canvas database directly
        const response = await SisAPI.syncCanvas(client.id)
        if (response) {
          await onReloadLearners()
          toastSuccess('Enrolments has been synced successfully')
        } else {
          toastError('Failed to sync enrolments')
        }
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to sync enrolments')
    } finally {
      setIsSyncingCanvas(false)
    }
  }

  const onSyncInsights = async () => {
    try {
      setIsSyncingInsights(true)
      if (client) {
        // sync insights database
        const response = await SisAPI.syncInsightsLearners(client.id)
        if (response) {
          await onReloadLearners()
          toastSuccess('LMS has been synced successfully')
        } else {
          toastError('Failed to sync LMS')
        }
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to sync LMS')
    } finally {
      setIsSyncingInsights(false)
    }
  }

  const onSyncEoi = async () => {
    try {
      setIsSyncingEoi(true)
      if (client) {
        const response = await SisAPI.syncEoiLearners(client.id)
        if (response) {
          await onReloadLearners()
          toastSuccess('EOI has been synced successfully')
        } else {
          toastError('Failed to sync EOI')
        }
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to sync EOI')
    } finally {
      setIsSyncingEoi(false)
    }
  }

  const onExportExcel = async () => {
    try {
      setIsExporting(true)
      if (client) {
        const response = await SisAPI.exportLearners(client.id)
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        // file name is program name replace special characters
        const fileName = client.name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
        link.setAttribute('download', `report-${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to export the report')
    } finally {
      setIsExporting(false)
    }
  }

  const hasColumnLocation = useMemo(() => {
    return tableHeaders.some(header => header.key === 'location')
  }, [tableHeaders])

  const hasColumnBusinessUnit = useMemo(() => {
    return tableHeaders.some(header => header.key === 'businessUnit')
  }, [tableHeaders])

  const updateTableHeaders = (showCompanyInformation: boolean) => {
    const filteredTableHeaders = client?.settings?.adminHub.tableHeaders.filter((header: any) => {
      if (showCompanyInformation) {
        return true
      }
      return !refCompanyInformationKeys.current.includes(header.key)
    })
    setTableHeaders(filteredTableHeaders || [])
  }

  const onUpdatedLearner = useCallback(
    (data: { learner: SisLearnerWithCourse }) => {
      if (data.learner) {
        onReloadLearners()
      }
    },
    [onReloadLearners]
  )

  const onAddedLearner = useCallback(
    (data: { learner: SisLearnerWithCourse }) => {
      if (learners && data.learner) {
        onReloadLearners()
      }
    },
    [learners, onReloadLearners]
  )

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  useEffect(() => {
    Emitter.on(Events.OnUpdatedLearner, onUpdatedLearner)
    Emitter.on(Events.OnAddedLearner, onAddedLearner)
    return () => {
      Emitter.off(Events.OnUpdatedLearner, onUpdatedLearner)
      Emitter.off(Events.OnAddedLearner, onAddedLearner)
    }
  }, [onAddedLearner, onUpdatedLearner])

  const renderContent = () => {
    return (
      <>
        <div className="flex grow w-full flex-col items-start gap-[12px] bg-white p-0 overflow-hidden">
          {isLoading && (
            <div className="flex w-full h-full items-center justify-center">
              <LoadingIndicator color={'#000'} />
            </div>
          )}
          {!isLoading && (
            <div className="overflow-auto w-full p-16 flex flex-col gap-[12px]">
              {client && (
                <>
                  <div className="flex flex-col gap-[12px] text-white w-full">
                    <div className="flex flex-row items-center gap-[12px]">
                      <div className="eoi-subtitle">
                        <span>
                          <span>
                            {client.name} / <span className="text-primary">{numFilteredLearners}</span> unique learners{' '}
                          </span>
                          / <span className="text-primary">{numFilteredRecords}</span> unique records
                        </span>
                      </div>
                      <div className="grow" />
                      <Button
                        label="Sync enrolments"
                        disabled={isSyncingCanvas}
                        isLoading={isSyncingCanvas}
                        onClick={onSyncCanvas}
                        className="button-primary w-[172px]"
                      />
                      <Button
                        label="Sync LMS data"
                        disabled={isSyncingInsights}
                        isLoading={isSyncingInsights}
                        onClick={onSyncInsights}
                        className="button-primary w-[160px]"
                      />
                      <Button
                        label="Sync EOI"
                        disabled={isSyncingEoi}
                        isLoading={isSyncingEoi}
                        onClick={onSyncEoi}
                        className="button-primary w-[140px]"
                      />
                      <button
                        onClick={onResetFilters}
                        disabled={!hasFilterOrSearch}
                        className="button-secondary w-[170px] whitespace-nowrap">
                        Reset filters
                      </button>
                      <Button
                        label="Export Excel"
                        disabled={isExporting}
                        isLoading={isExporting}
                        onClick={onExportExcel}
                        className="button-primary w-[140px]"
                      />
                    </div>
                  </div>
                </>
              )}

              {!isLoading && learners && (
                <>
                  <div className="flex flex-row items-center w-full gap-2">
                    <div className="w-full min-w-[300px]">
                      <SearchInput
                        ref={refSearchInput}
                        onInputChanged={onInputChangedSearch}
                        onEnter={onEnterSearch}
                        isSearching={isSearching}
                        placeholder="Name or email"
                      />
                    </div>

                    <DropDownMenu
                      ref={refDropDownProgram}
                      items={programs}
                      onSelected={onSelectedProgram}
                      style={{ zIndex: 30, fontSize: '14px' }}
                      multiSelect={true}
                      onMultiSelected={onMultiSelectedProgram}
                    />

                    <DropDownMenu
                      ref={refDropDownCohort}
                      items={cohorts}
                      onSelected={onSelectedCohort}
                      style={{ zIndex: 30, fontSize: '14px' }}
                      multiSelect={true}
                      onMultiSelected={onMultiSelectedCohort}
                    />

                    {hasColumnLocation && (
                      <DropDownMenu
                        ref={refDropDownLocation}
                        items={locations}
                        onSelected={onSelectedLocation}
                        style={{ zIndex: 30, fontSize: '14px' }}
                        multiSelect={true}
                        onMultiSelected={onMultiSelectedLocation}
                      />
                    )}
                    {hasColumnBusinessUnit && (
                      <DropDownMenu
                        ref={refDropDownBusinessUnit}
                        items={businessUnits}
                        onSelected={onSelectedBusinessUnit}
                        style={{ zIndex: 30, fontSize: '14px' }}
                        multiSelect={true}
                        onMultiSelected={onMultiSelectedBusinessUnit}
                      />
                    )}

                    <DropDownMenu
                      ref={refDropDownLearnerStatus}
                      items={learnerStatuses}
                      onSelected={onSelectedLearnerStatus}
                      style={{ zIndex: 30, fontSize: '14px' }}
                      multiSelect={true}
                      onMultiSelected={onMultiSelectedLearnerStatus}
                    />
                  </div>

                  <div className="flex flex-col gap-[6px] w-full text-[12px]">
                    <div className="flex flex-row gap-[12px] text-left font-inter">
                      <Checkbox
                        id={'checkbox-show-company-information'}
                        checked={checkboxShowCompanyInformation}
                        onChange={checked => {
                          setCheckboxShowCompanyInformation(checked)
                          updateTableHeaders(checked)
                        }}
                        theme={CheckboxTheme.Normal}
                        label={<span>Show company information</span>}
                      />
                      <Checkbox
                        id={'checkbox-show-most-recent'}
                        checked={checkboxShowMostRecent}
                        onChange={checked => {
                          setCheckboxShowMostRecent(checked)
                        }}
                        theme={CheckboxTheme.Normal}
                        label={<span>Show most recent program/course only</span>}
                      />
                      <div className="grow"></div>
                      <button
                        className="button-secondary button-secondary-stroke w-[110px] h-[30px]"
                        onClick={onReloadLearners}
                        disabled={isReloading}>
                        <div className="flex flex-row gap-[12px] items-center justify-center group">
                          {isReloading ? (
                            <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'16px'} width={'16px'} />
                          ) : (
                            <>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.23077 11.9375C6.23077 11.9375 5.46952 12.3125 8.23077 12.3125C9.21968 12.3125 10.1864 12.0193 11.0086 11.4698C11.8309 10.9204 12.4717 10.1395 12.8502 9.22592C13.2286 8.31229 13.3276 7.30695 13.1347 6.33705C12.9418 5.36714 12.4656 4.47623 11.7663 3.77697C11.067 3.0777 10.1761 2.6015 9.20622 2.40857C8.23632 2.21565 7.23099 2.31466 6.31735 2.6931C5.40372 3.07154 4.62283 3.7124 4.07342 4.53465C3.52402 5.35689 3.23077 6.32359 3.23077 7.3125"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M8.23077 14.6875L5.73077 12.1875L8.23077 9.6875"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span className="text-[14px]">Refresh</span>
                            </>
                          )}
                        </div>
                      </button>
                      {canEditStatus && (
                        <button
                          className="button-secondary button-secondary-stroke w-[120px] h-[30px]"
                          onClick={onEditStatus}>
                          <div className="flex flex-row gap-[12px] items-center justify-center group">
                            <span className="text-[14px]">Edit status</span>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>

                  <div
                    ref={refTableDiv}
                    className="flex flex-col gap-4 text-white max-h-[100%] w-full overflow-auto table-scrollbar table-scrollbar-grey">
                    <table className="mentem-table mentem-table-highlight text-black">
                      <thead>
                        <tr>
                          {tableHeaders.map((header, index: number) => {
                            return (
                              <th
                                className={`${alternateStyle('th', index === 0)} h-[55px] ${
                                  header.width ? header.width : 'min-w-[200px]'
                                }`}
                                key={header.key}>
                                <div className="flex flex-row items-center gap-2 px-2">
                                  <span className="grow">{header.value}</span>
                                  {header.sorting !== false && (
                                    <img
                                      className="cursor-pointer"
                                      src={index % 2 === 0 ? sortSvg : sort2Svg}
                                      alt="sort"
                                      onClick={() => onClickSort(header.key)}
                                      data-tooltip-id={`tooltip-${header.key}`}
                                    />
                                  )}
                                </div>
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {sortedLearners.map(item => {
                          if (item.children && item.children.length > 0) {
                            return (
                              <React.Fragment key={item.id}>
                                {renderRow(item)}
                                {expandedLearnerMap[item.id] &&
                                  item.children.map(child => {
                                    return renderRow(child, true)
                                  })}
                              </React.Fragment>
                            )
                          } else {
                            return renderRow(item)
                          }
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Button
                    label={'Add learner'}
                    onClick={onAddNew}
                    isLoading={false}
                    disabled={false}
                    className="button-primary w-[140px] whitespace-nowrap self-end"
                  />
                </>
              )}
            </div>
          )}
        </div>
        {showEditLearner && selectedLearner && client && (
          <ModalSisAddOrEditLearner
            learner={selectedLearner}
            isNew={isNewAdd}
            client={client}
            onClose={() => {
              setShowEditLearner(false)
            }}
          />
        )}
        {showHistory && selectedLearner && (
          <ModalSisHistory
            learner={selectedLearner}
            onClose={() => {
              setShowHistory(false)
            }}
          />
        )}
        {showNote && selectedLearner && (
          <ModalSisEditNote
            learner={selectedLearner}
            onClose={(updated, updatedLearner) => {
              if (updated && updatedLearner) {
                onUpdatedLearner({ learner: updatedLearner })
                toastSuccess('Note has been updated')
              }
              setShowNote(false)
            }}
          />
        )}
        {showEditStatus && (
          <ModalSisEditStatus
            learners={learners.filter(learner => learner.courseToLearnerId)}
            preCheckedIds={preCheckedIdsForStatus}
            onClose={updated => {
              if (updated) {
                onReloadLearners()
                toastSuccess('Status has been updated')
              }
              setShowEditStatus(false)
            }}
          />
        )}
        {showConfirmation && (
          <ModalSisConfirmation
            data={confirmationData}
            isLoading={isUpdatingStatus}
            onClose={async confirm => {
              if (confirm && refConfirmationPayload.current) {
                setIsUpdatingStatus(true)
                // update status
                const body = {
                  status: confirmationData.newStatus
                }
                const success = await updateLearner(
                  confirmationData.learner.courseId || '',
                  confirmationData.learner.id || '',
                  body
                )
                // reset
                if (success) {
                  Emitter.emit(Events.OnConfirmedPreselectedItem, {
                    id: refPreSelectedDropdownId.current
                  })
                  refPreSelectedDropdownId.current = undefined
                  refConfirmationPayload.current = undefined
                  setShowConfirmation(false)
                  setIsUpdatingStatus(false)
                }
              } else {
                setShowConfirmation(false)
              }
            }}
          />
        )}
      </>
    )
  }

  return (
    <>
      {forIframe ? (
        <div className="flex grow relative h-screen overflow-auto">{renderContent()}</div>
      ) : (
        <SidebarLayout>
          <div className="min-w-[1px]" />
          {renderContent()}
        </SidebarLayout>
      )}
      {tableHeaders.map(header => {
        return (
          <Tooltip
            id={`tooltip-${header.key}`}
            key={header.key}
            className="mentem-tooltip"
            style={getTooptipStyle(header.key)}
            place="top"
            noArrow={true}>
            {getSortingText(header.key)}
          </Tooltip>
        )
      })}
      <Tooltip
        id={`icon-history`}
        className="mentem-tooltip"
        style={{
          ...tooltipStyle
        }}
        place="top"
        noArrow={true}>
        Click to see historical timestamps
      </Tooltip>
    </>
  )
}
