import { useContext, createContext } from 'react'
import { type Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import invariant from 'tiny-invariant'

type CleanupFn = () => void

export type ItemPosition = 'first' | 'last' | 'middle' | 'only'

export type ItemEntry = { itemId: string; element: HTMLElement }

export type Item = {
  id: string
  label: string
  data: any
}

const itemKey = Symbol('item')
type ItemData = {
  [itemKey]: true
  item: Item
  index: number
  instanceId: symbol
}

export function getItemData({ item, index, instanceId }: { item: Item; index: number; instanceId: symbol }): ItemData {
  return {
    [itemKey]: true,
    item,
    index,
    instanceId
  }
}

export function isItemData(data: Record<string | symbol, unknown>): data is ItemData {
  return data[itemKey] === true
}

export function getItemPosition({ index, items }: { index: number; items: Item[] }): ItemPosition {
  if (items.length === 1) {
    return 'only'
  }

  if (index === 0) {
    return 'first'
  }

  if (index === items.length - 1) {
    return 'last'
  }

  return 'middle'
}

export function getItemRegistry() {
  const registry = new Map<string, HTMLElement>()

  function register({ itemId, element }: ItemEntry) {
    registry.set(itemId, element)

    return function unregister() {
      registry.delete(itemId)
    }
  }

  function getElement(itemId: string): HTMLElement | null {
    return registry.get(itemId) ?? null
  }

  return { register, getElement }
}

export type ListState = {
  items: Item[]
  lastCardMoved: {
    item: Item
    previousIndex: number
    currentIndex: number
    numberOfItems: number
  } | null
}

export type ListContextValue = {
  getListLength: () => number
  registerItem: (entry: ItemEntry) => CleanupFn
  reorderItem: (args: { startIndex: number; indexOfTarget: number; closestEdgeOfTarget: Edge | null }) => void
  instanceId: symbol
}

export const ListContext = createContext<ListContextValue | null>(null)

export function useListContext() {
  const listContext = useContext(ListContext)
  invariant(listContext !== null)
  return listContext
}
