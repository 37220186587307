import { useEffect, useState } from 'react'
import { SisArchitectureCourse, SisProgram } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import { toastError } from '../utils/toast'

const defaultProgram: DropDownItem = {
  id: '0',
  name: 'Choose the program you’d like to map to',
  value: undefined,
  isLabel: true
}

const defaultCohort: DropDownItem = {
  id: '0',
  name: 'Choose the cohort you’d like to map to',
  value: undefined,
  isLabel: true
}

interface Props {
  programs: SisProgram[]
  course: SisArchitectureCourse
  onClose: (updated: boolean) => void
}

export default function ModalSisCourseMapping({ programs, course, onClose }: Props): JSX.Element {
  const [isSaving, setIsSaving] = useState(false)
  const [programOptions, setProgramOptions] = useState<DropDownItem[]>([defaultProgram])
  const [cohortOptions, setCohortOptions] = useState<DropDownItem[]>([defaultCohort])
  const [selectedProgram, setSelectedProgram] = useState<DropDownItem | null>(null)
  const [selectedCohort, setSelectedCohort] = useState<DropDownItem | null>(null)

  const canSave = selectedProgram && selectedCohort

  const handleSave = async () => {
    try {
      if (!selectedProgram || !selectedCohort) {
        toastError('Please select program and cohort')
        return
      }
      setIsSaving(true)
      await addCourseToCohort(course.id, selectedCohort.value)
      onClose(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const addCourseToCohort = async (courseId: string, cohortId: string) => {
    try {
      await SisAPI.mapCoursesToCohort(cohortId, [courseId])
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    }
  }

  const onSelectedProgram = (item: DropDownItem) => {
    setSelectedProgram(item)
    // update cohort options by program id
    const programId = item.value
    const program = programs.find(program => program.id === programId)
    if (!program) {
      return
    }

    const cohortOptions = program.cohorts.map(cohort => {
      return {
        id: cohort.id,
        name: cohort.name,
        value: cohort.id
      }
    })

    const positions = program.positions
    if (positions && positions.length > 0) {
      // sort cohortOptions
      cohortOptions.sort((a, b) => {
        const aPosition = positions.indexOf(a.id)
        const bPosition = positions.indexOf(b.id)
        if (aPosition === -1) {
          return 1
        }
        if (bPosition === -1) {
          return -1
        }
        return aPosition - bPosition
      })
    }

    setCohortOptions([defaultCohort, ...cohortOptions])
  }

  const onSelectedCohort = (item: DropDownItem) => {
    setSelectedCohort(item)
  }

  const getTitle = (course: SisArchitectureCourse) => {
    if (!course.clientName) {
      return course.name
    }
  }

  useEffect(() => {
    const prgramOptions = programs.map(program => {
      return {
        id: program.id,
        name: program.name,
        value: program.id
      }
    })
    setProgramOptions([defaultProgram, ...prgramOptions])
  }, [programs])

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center Overlay">
      <div className="h-auto w-auto min-w-[30%] flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center gap-[24px] px-8 w-full">
          <p className="text-[16px] font-bold text-left font-roboto w-[100%]">{getTitle(course)}</p>
          <div className="flex flex-col gap-4 w-full">
            <div className="w-full">
              <DropDownMenu
                items={programOptions}
                onSelected={onSelectedProgram}
                style={{ justifyItems: 'center', zIndex: 30, fontSize: '14px' }}
              />
            </div>
            <div className="w-full">
              <DropDownMenu
                items={cohortOptions}
                onSelected={onSelectedCohort}
                style={{ justifyItems: 'center', zIndex: 30, fontSize: '14px' }}
              />
            </div>
          </div>
          <Button label="Save" disabled={isSaving || !canSave} isLoading={isSaving} onClick={handleSave} />
        </div>
      </div>
    </div>
  )
}
