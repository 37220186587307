import { isDevelopment, isLocal } from '../utils/EnvUtils'

export interface MenuItem {
  title: string
  path: string
  icon: string | undefined
}

const Path = {
  root: { title: 'Home', path: '/', icon: 'home' } as MenuItem,
  error: { title: 'Error', path: '/error' } as MenuItem,
  clients: {
    title: 'Clients',
    path: '/clients',
    icon: 'home'
  } as MenuItem,
  client: {
    title: 'Client',
    path: '/client/:orgId'
  } as MenuItem,
  debug: {
    title: 'Debug',
    path: '/debug'
  } as MenuItem,
  administrators: {
    title: 'User management',
    path: '/user-management',
    icon: 'settings'
  } as MenuItem,
  lxe: {
    title: 'LX Engine',
    path: '/lxe',
    icon: 'settings'
  } as MenuItem,
  assessments: {
    title: 'Assessments',
    path: '/assessments',
    icon: 'settings'
  } as MenuItem,
  assessmentReport: {
    title: 'Assessment report',
    path: '/assessment-report/:assessmentId'
  } as MenuItem,
  assessmentIndividual: {
    title: 'Assessment individual report',
    path: '/assessment-individual/:candidateId'
  } as MenuItem,
  eoi: {
    title: 'EOI',
    path: '/eoi',
    icon: 'settings'
  } as MenuItem,
  eoiForClient: {
    title: 'EOI',
    path: '/eoi/reports',
    icon: 'settings'
  } as MenuItem,
  eoiProgramReport: {
    title: 'EOI program report',
    path: '/eoi/program-report/:clientCode/:programCode'
  } as MenuItem,
  eoiProgramReportForClient: {
    title: 'EOI program report',
    path: '/eoi/:clientCode/:programCode'
  } as MenuItem,
  learners: {
    title: 'Learners',
    path: '/learners',
    icon: 'settings'
  },
  learnersDetails: {
    title: 'Learners',
    path: '/learners/:clientId',
    icon: 'settings'
  },
  learnersDetailsForClient: {
    title: 'Learners',
    path: '/learners-for-client',
    icon: 'settings'
  },
  logout: {
    title: 'Logout',
    path: '/login',
    icon: 'logout'
  } as MenuItem
}

const Menu: any[] = []
const MenuForAdmin = [Path.clients, Path.lxe, Path.administrators, Path.assessments, Path.eoi]
const MenuForUser = [Path.assessments, Path.eoi]

// TODO: update this when the feature is ready
if (isDevelopment || isLocal) {
  MenuForAdmin.push(Path.learners)
}

export default Path
export { Menu, MenuForAdmin, MenuForUser }
