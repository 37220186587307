import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { SisCohort, SisProgram } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import { toastError, toastSuccess } from '../utils/toast'
import LoadingIndicator from '../components/LoadingIndicator'
import ModalSisDeleteConfirmation from './ModalSisDeleteConfirmation'

import { type Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { Stack, xcss } from '@atlaskit/primitives'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import * as liveRegion from '@atlaskit/pragmatic-drag-and-drop-live-region'
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash'
import {
  getItemRegistry,
  isItemData,
  Item,
  ListContext,
  ListContextValue,
  ListState
} from '../components/drag-and-drop/ListContext'
import { getItemPosition } from '../components/drag-and-drop/ListItem'
import CohortListItem from '../components/drag-and-drop/CohortListItem'

const containerStyles = xcss({
  maxWidth: '100%'
  // borderWidth: 'border.width',
  // borderStyle: 'solid',
  // borderColor: 'color.border'
})

const defaultProgram: DropDownItem = {
  id: '0',
  name: 'Choose the program to manage cohort',
  value: undefined,
  isLabel: true
}

enum ManageView {
  Programs,
  Cohorts
}

interface Props {
  clientId: string
  onClose: () => void
}

export default function ModalSisManagePrograms({ clientId, onClose }: Props): JSX.Element {
  const [view, setView] = useState<ManageView>(ManageView.Programs)
  const [isLoading, setIsLoading] = useState(true)
  const [programs, setPrograms] = useState<SisProgram[]>([])
  const [cohorts, setCohorts] = useState<SisCohort[]>([])
  const [programOptions, setProgramOptions] = useState<DropDownItem[]>([defaultProgram])
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(null)
  const [addingProgram, setAddingProgram] = useState(false)
  const [addingCohort, setAddingCohort] = useState(false)
  const [renameProgramMap, setRenameProgramMap] = useState<{ [key: string]: boolean }>({})
  const [deleteProgramMap, setDeleteProgramMap] = useState<{ [key: string]: boolean }>({})
  const [renameCohortMap, setRenameCohortMap] = useState<{ [key: string]: boolean }>({})
  const [deleteCohortMap, setDeleteCohortMap] = useState<{ [key: string]: boolean }>({})
  const refInputAddProgram = useRef<HTMLInputElement>(null)
  const refInputRenameProgram = useRef<{ [key: string]: HTMLInputElement | null }>({})
  const refInputAddCohort = useRef<HTMLInputElement>(null)
  // const refInputRenameCohort = useRef<{ [key: string]: HTMLInputElement | null }>({})
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [deleteProgramId, setDeleteProgramId] = useState<string | null>(null)
  const [deleteCohortId, setDeleteCohortId] = useState<string | null>(null)

  // cohort items for drag-and-drop
  const [{ items, lastCardMoved }, setListState] = useState<ListState>({
    items: [],
    lastCardMoved: null
  })
  const [registry] = useState(getItemRegistry)

  // Isolated instances of this component from one another
  const [instanceId] = useState(() => Symbol('cohorts'))

  const reorderItem = useCallback(
    ({
      startIndex,
      indexOfTarget,
      closestEdgeOfTarget
    }: {
      startIndex: number
      indexOfTarget: number
      closestEdgeOfTarget: Edge | null
    }) => {
      const finishIndex = getReorderDestinationIndex({
        startIndex,
        closestEdgeOfTarget,
        indexOfTarget,
        axis: 'vertical'
      })

      if (finishIndex === startIndex) {
        // If there would be no change, we skip the update
        return
      }

      setListState(listState => {
        const item = listState.items[startIndex]

        return {
          items: reorder({
            list: listState.items,
            startIndex,
            finishIndex
          }),
          lastCardMoved: {
            item,
            previousIndex: startIndex,
            currentIndex: finishIndex,
            numberOfItems: listState.items.length
          }
        }
      })
    },
    []
  )

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isItemData(source.data) && source.data.instanceId === instanceId
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0]
        if (!target) {
          return
        }

        const sourceData = source.data
        const targetData = target.data
        if (!isItemData(sourceData) || !isItemData(targetData)) {
          return
        }

        const indexOfTarget = items.findIndex(item => item.id === targetData.item.id)
        if (indexOfTarget < 0) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData)

        reorderItem({
          startIndex: sourceData.index,
          indexOfTarget,
          closestEdgeOfTarget
        })
      }
    })
  }, [instanceId, items, reorderItem])

  // once a drag is finished, we have some post drop actions to take
  useEffect(() => {
    if (lastCardMoved === null) {
      return
    }

    const { item, previousIndex, currentIndex, numberOfItems } = lastCardMoved
    const element = registry.getElement(item.id)
    if (element) {
      triggerPostMoveFlash(element)
    }

    liveRegion.announce(
      `You've moved ${item.label} from position ${previousIndex + 1} to position ${
        currentIndex + 1
      } of ${numberOfItems}.`
    )
  }, [lastCardMoved, registry])

  // cleanup the live region when this component is finished
  useEffect(() => {
    return function cleanup() {
      liveRegion.cleanup()
    }
  }, [])

  // get the latest cohort order and update it
  useEffect(() => {
    // console.log(items)
    // compare current order in program
    if (selectedProgramId) {
      const selectedProgram = programs.find(program => program.id === selectedProgramId)
      if (selectedProgram) {
        const currentPositions = selectedProgram.positions || []
        const newPositions = items.map(item => item.id)
        if (currentPositions.join(',') !== newPositions.join(',')) {
          // update positions
          SisAPI.updateProgram(selectedProgramId, undefined, newPositions).then(() => {
            // console.log(`updated currentPositions: ${currentPositions}`)
            // console.log(`updated newPositions: ${newPositions}`)
            // update program
            const newPrograms = programs.map(program => {
              if (program.id === selectedProgramId) {
                program.positions = newPositions
              }
              return program
            })
            setPrograms(newPrograms)
          })
        }
      }
    }
  }, [items, programs, selectedProgramId])

  const getListLength = useCallback(() => items.length, [items.length])

  const contextValue: ListContextValue = useMemo(() => {
    return {
      registerItem: registry.register,
      reorderItem,
      instanceId,
      getListLength
    }
  }, [registry.register, reorderItem, instanceId, getListLength])

  const addProgram = async (name: string) => {
    try {
      setAddingProgram(true)
      const newProgram = await SisAPI.addProgram(name, clientId)
      newProgram.cohorts = [] // initialize cohorts
      setPrograms([...programs, newProgram])

      // update program options
      const options = programOptions.concat({ id: newProgram.id, name: newProgram.name, value: newProgram.id })
      setProgramOptions([defaultProgram, ...options])

      // reset input
      refInputAddProgram.current!.value = ''
      toastSuccess('Program added successfully')
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setAddingProgram(false)
    }
  }
  const updateProgram = async (programId: string) => {
    const name = refInputRenameProgram.current[programId]?.value
    if (!name) {
      toastError('Please enter program name')
      return
    }
    try {
      setRenameProgramMap({ ...renameProgramMap, [programId]: true })
      await SisAPI.updateProgram(programId, name)
      const newPrograms = programs.map(program => {
        if (program.id === programId) {
          program.name = name
        }
        return program
      })
      setPrograms(newPrograms)

      // update program options
      const options = newPrograms.map(program => ({ id: program.id, name: program.name, value: program.id }))
      setProgramOptions([defaultProgram, ...options])

      toastSuccess('Program updated successfully')
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setRenameProgramMap({ ...renameProgramMap, [programId]: false })
    }
  }

  const onDeleteProgram = (programId: string) => {
    setDeleteProgramId(programId)
    setShowDeleteConfirmation(true)
    setConfirmationMessage('Are you sure you want to delete this program?')
  }

  const deleteProgram = async (programId: string) => {
    try {
      setDeleteProgramMap({ ...deleteProgramMap, [programId]: true })
      await SisAPI.deleteProgram(programId)
      const newPrograms = programs.filter(program => program.id !== programId)
      setPrograms(newPrograms)
      // update program options
      const options = newPrograms.map(program => ({ id: program.id, name: program.name, value: program.id }))
      setProgramOptions([defaultProgram, ...options])

      toastSuccess('Program deleted successfully')
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setDeleteProgramMap({ ...deleteProgramMap, [programId]: false })
      setDeleteProgramId(null)
    }
  }

  const onAddProgram = async () => {
    const name = refInputAddProgram.current?.value
    if (!name) {
      toastError('Please enter program name')
      return
    }
    await addProgram(name)
  }

  const updateCohortList = (programId: string, cohorts: SisCohort[]) => {
    let positions: string[] = []
    const selectedProgram = programs.find(program => program.id === programId)
    if (selectedProgram) {
      positions = selectedProgram.positions || []
    }

    // sort by positions
    const tmpCohorts = [...cohorts].sort((a, b) => {
      const aIndex = positions.indexOf(a.id)
      const bIndex = positions.indexOf(b.id)
      if (aIndex === -1 && bIndex === -1) {
        return 0
      }
      if (aIndex === -1) {
        return 1
      }
      if (bIndex === -1) {
        return -1
      }
      return aIndex - bIndex
    })

    // console.log(selectedProgramId, positions)
    // tmpCohorts.forEach(a => {
    //   console.log(a.id, a.name, positions.indexOf(a.id))
    // })

    const tmpItems: Item[] = tmpCohorts.map(cohort => ({
      id: cohort.id,
      label: cohort.name,
      data: cohort
    }))
    setListState({
      items: tmpItems,
      lastCardMoved: null
    })
  }

  const onSelectedProgram = (item: DropDownItem) => {
    setSelectedProgramId(item.id)
    if (item.value) {
      const programId = item.value as string
      const program = programs.find(p => p.id === programId)
      if (program) {
        const tmpCohorts = [...program.cohorts]
        setCohorts(tmpCohorts)
        updateCohortList(programId, tmpCohorts)
      }
    }
  }

  const onAddCohort = async () => {
    const name = refInputAddCohort.current?.value
    if (!name) {
      toastError('Please enter cohort name')
      return
    }
    if (!selectedProgramId) {
      toastError('Please select program')
      return
    }
    await addCohort(name, selectedProgramId)
  }

  const addCohort = async (name: string, programId: string) => {
    try {
      setAddingCohort(true)
      const newCohort = await SisAPI.addCohort(name, programId)
      const newPrograms = programs.map(program => {
        if (program.id === programId) {
          program.cohorts.push(newCohort)
        }
        return program
      })
      setPrograms(newPrograms)

      // update cohort list
      const updatedCohorts = [...cohorts, newCohort]
      setCohorts(updatedCohorts)
      updateCohortList(programId, updatedCohorts)

      // reset input
      refInputAddCohort.current!.value = ''
      toastSuccess('Cohort added successfully')
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setAddingCohort(false)
    }
  }

  const updateCohort = async (cohortId: string, newValue: string) => {
    try {
      // const name = refInputRenameCohort.current[cohortId]?.value
      const name = newValue
      if (!name) {
        toastError('Please enter cohort name')
        return
      }
      setRenameCohortMap({ ...renameCohortMap, [cohortId]: true })
      await SisAPI.updateCohort(cohortId, name)
      const newPrograms = programs.map(program => {
        program.cohorts = program.cohorts?.map(cohort => {
          if (cohort.id === cohortId) {
            cohort.name = name
          }
          return cohort
        })
        return program
      })
      setPrograms(newPrograms)
      toastSuccess('Cohort updated successfully')
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setRenameCohortMap({ ...renameCohortMap, [cohortId]: false })
    }
  }

  const onDeleteCohort = (cohortId: string) => {
    setDeleteCohortId(cohortId)
    setShowDeleteConfirmation(true)
    setConfirmationMessage('Are you sure you want to delete this cohort?')
  }

  const deleteCohort = async (cohortId: string) => {
    try {
      setDeleteCohortMap({ ...deleteCohortMap, [cohortId]: true })
      await SisAPI.deleteCohort(cohortId)
      const newPrograms = programs.map(program => {
        program.cohorts = program.cohorts?.filter(cohort => cohort.id !== cohortId)
        return program
      })
      setPrograms(newPrograms)

      // update cohort list
      const updatedCohorts = cohorts.filter(cohort => cohort.id !== cohortId)
      setCohorts(updatedCohorts)

      if (selectedProgramId) {
        updateCohortList(selectedProgramId, updatedCohorts)
        toastSuccess('Cohort deleted successfully')
      } else {
        toastError('Please select program')
      }
    } catch (error) {
      console.log('error', error)
      toastError(`${error}`)
    } finally {
      setDeleteCohortMap({ ...deleteCohortMap, [cohortId]: false })
      setDeleteCohortId(null)
    }
  }

  const fetchPrograms = useCallback(
    async (sliently: boolean = false) => {
      try {
        if (!sliently) {
          setIsLoading(true)
        }
        const data = await SisAPI.getPrograms(clientId)
        // sort by alphabet name
        data.sort((a, b) => a.name.localeCompare(b.name))
        setPrograms(data)

        // set program options
        const options = data.map(program => ({ id: program.id, name: program.name, value: program.id }))
        setProgramOptions([defaultProgram, ...options])
      } catch (error) {
        console.log('error', error)
        toastError(`${error}`)
      } finally {
        if (!sliently) {
          setIsLoading(false)
        }
      }
    },
    [clientId]
  )

  useEffect(() => {
    fetchPrograms()
  }, [fetchPrograms])

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center Overlay">
      <div className="min-h-[70%] h-[70%] max-h-[70%] w-1/2 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="flex flex-col items-center gap-[24px] px-8 w-full h-full overflow-y-auto">
          {!isLoading && (
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-row w-full sticky top-0 bg-white z-10">
                <button
                  className={`flex-1 text-[14px] font-bold ${
                    view === ManageView.Programs
                      ? 'text-white bg-[#BD69FE] border border-[#B5B5BE]'
                      : 'text-black border-t border-b border-l border-[#B5B5BE] bg-white shadow-[0px_120px_34px_0px_rgba(0,0,0,0.00),0px_77px_31px_0px_rgba(0,0,0,0.01),0px_43px_26px_0px_rgba(0,0,0,0.05),0px_19px_19px_0px_rgba(0,0,0,0.09),0px_5px_11px_0px_rgba(0,0,0,0.10)]'
                  }`}
                  onClick={() => {
                    // reset selected program
                    setSelectedProgramId(null)
                    setCohorts([])
                    setListState({
                      items: [],
                      lastCardMoved: null
                    })
                    setView(ManageView.Programs)
                  }}>
                  <div className="flex flex-col items-center gap-[3px] py-[10px]">
                    <div>Manage programs</div>
                  </div>
                </button>
                <button
                  className={`flex-1 text-[14px] font-bold ${
                    view === ManageView.Cohorts
                      ? 'text-white bg-[#BD69FE] border border-[#B5B5BE]'
                      : 'text-black border-t border-b border-r border-[#B5B5BE] bg-white shadow-[0px_120px_34px_0px_rgba(0,0,0,0.00),0px_77px_31px_0px_rgba(0,0,0,0.01),0px_43px_26px_0px_rgba(0,0,0,0.05),0px_19px_19px_0px_rgba(0,0,0,0.09),0px_5px_11px_0px_rgba(0,0,0,0.10)]'
                  }`}
                  onClick={() => setView(ManageView.Cohorts)}>
                  <div className="flex flex-col items-center gap-[3px] py-[10px]">
                    <div>Manage cohorts</div>
                  </div>
                </button>
              </div>

              {view === ManageView.Programs && (
                <>
                  <div className="flex flex-row w-full gap-4">
                    <input
                      type="text"
                      className="input-text"
                      placeholder="Program name"
                      ref={refInputAddProgram}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onAddProgram()
                        }
                      }}
                    />
                    <Button
                      label="Add program"
                      className="button-secondary whitespace-nowrap w-[170px]"
                      disabled={addingProgram}
                      isLoading={addingProgram}
                      onClick={onAddProgram}
                    />
                  </div>

                  <div className="flex flex-col gap-4 w-full">
                    {programs.map(program => (
                      <div key={program.id} className="flex flex-row items-center gap-4">
                        <input
                          type="text"
                          className="input-text"
                          defaultValue={program.name}
                          ref={el => (refInputRenameProgram.current[program.id] = el)}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              updateProgram(program.id)
                            }
                          }}
                        />
                        <Button
                          label="Delete"
                          className="button-primary w-[120px]"
                          disabled={deleteProgramMap[program.id]}
                          isLoading={deleteProgramMap[program.id]}
                          onClick={() => onDeleteProgram(program.id)}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {view === ManageView.Cohorts && (
                <>
                  <div className="w-[100%]">
                    <DropDownMenu
                      items={programOptions}
                      onSelected={onSelectedProgram}
                      style={{ zIndex: 30, fontSize: '14px' }}
                    />
                  </div>

                  {selectedProgramId && (
                    <>
                      <div className="flex flex-row w-full gap-4">
                        <input
                          type="text"
                          className="input-text"
                          placeholder="Cohort name"
                          ref={refInputAddCohort}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              onAddCohort()
                            }
                          }}
                        />
                        <Button
                          label="Add cohort"
                          className="button-secondary whitespace-nowrap w-[170px]"
                          isLoading={addingCohort}
                          disabled={addingCohort}
                          onClick={onAddCohort}
                        />
                      </div>
                      {/* {cohorts.map(cohort => (
                        <div key={cohort.id} className="flex flex-row items-center gap-4">
                          <input
                            type="text"
                            className="input-text"
                            defaultValue={cohort.name}
                            ref={el => (refInputRenameCohort.current[cohort.id] = el)}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                updateCohort(cohort.id)
                              }
                            }}
                          />
                          <Button
                            label="Delete"
                            className="button-primary w-[120px]"
                            isLoading={deleteCohortMap[cohort.id]}
                            disabled={deleteCohortMap[cohort.id]}
                            onClick={() => onDeleteCohort(cohort.id)}
                          />
                        </div>
                      ))} */}
                    </>
                  )}
                  {items.length > 0 && (
                    <ListContext.Provider value={contextValue}>
                      <Stack xcss={containerStyles}>
                        {items.map((item, index) => (
                          <CohortListItem
                            key={item.id}
                            item={item}
                            index={index}
                            position={getItemPosition({ index, items })}
                            isLoading={deleteCohortMap[item.data.id]}
                            disabled={deleteCohortMap[item.data.id]}
                            onUpdate={updateCohort}
                            onDelete={onDeleteCohort}
                          />
                        ))}
                      </Stack>
                    </ListContext.Provider>
                  )}
                </>
              )}
            </div>
          )}
          {isLoading && (
            <div className="flex items-center justify-center h-full">
              <LoadingIndicator color="#000" />
            </div>
          )}
        </div>
      </div>
      {showDeleteConfirmation && (
        <ModalSisDeleteConfirmation
          message={confirmationMessage}
          onClose={(confirmed: boolean) => {
            if (confirmed) {
              if (deleteProgramId) {
                deleteProgram(deleteProgramId)
              } else if (deleteCohortId) {
                deleteCohort(deleteCohortId)
              }
            }
            setShowDeleteConfirmation(false)
          }}
        />
      )}
    </div>
  )
}
