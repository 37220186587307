import { useCallback, useEffect, useRef, useState } from 'react'
import ButtonLoadingIndicator from '../components/ButtonLoadingIndicator'
import { TextareaAutosize } from '@mui/material'
import { toastSuccess, toastError } from '../utils/toast'
import { SisAPI } from '../apis/SisAPI'
import { SisClient } from '../apis/entities/sis.entity'
import LoadingIndicator from '../components/LoadingIndicator'
// import { JsonEditor } from 'json-edit-react'

interface Props {
  onClose: () => void
  isAdminPlus: boolean
  clientId: string
}

export default function ModalSisCms({ onClose, isAdminPlus, clientId }: Props): JSX.Element {
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [client, setClient] = useState<SisClient | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [settings, setSettings] = useState<any>('')

  const onUpdateClient = async () => {
    try {
      if (!textareaRef.current) {
        return
      }
      setIsSaving(true)
      const settings = JSON.parse(textareaRef.current.value)
      const result = await SisAPI.updateClient(clientId, {
        settings
      })
      if (result) {
        toastSuccess('Client updated successfully')
      } else {
        toastError('Failed to update client')
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to update client')
    } finally {
      setIsSaving(false)
    }
  }

  const fetchClient = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await SisAPI.getClient(clientId)
      setClient(result)
      setSettings(result.settings || {})
    } catch (error) {
      console.error(error)
      toastError('Failed to fetch client')
    } finally {
      setIsLoading(false)
    }
  }, [clientId])

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center Overlay">
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-3/4 h-3/4 rounded-lg shadow-lg`}>
        {' '}
        <div className="flex justify-end text-2xl font-bold">
          <button onClick={onClose}>X</button>
        </div>
        {isLoading && (
          <div className="flex w-full h-full items-center justify-center">
            <LoadingIndicator color={'#000'} />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="flex flex-col text-2xl font-bold">
              <span>
                {} {isAdminPlus ? 'Edit' : 'View'} Client's settings - {client?.name}
              </span>
            </div>

            <br />
            <div className="w-full relative min-h-[70%] max-h-[70%] overflow-y-auto">
              <TextareaAutosize
                ref={textareaRef}
                className="w-full border border-gray-300 text-[14px] focus:outline-none"
                style={{ height: '1000px', resize: 'none' }}
                defaultValue={JSON.stringify(client?.settings || {}, null, 2)}
                readOnly={!isAdminPlus}
              />
              {/* <JsonEditor
                    rootName="settings"
                    minWidth={'100%'}
                    maxWidth={'1200px'}
                    showArrayIndices={false}
                    showCollectionCount={'when-closed'}
                    enableClipboard={false}
                    data={settings}
                    setData={setSettings}
                    stringTruncate={10000}
                  /> */}
            </div>

            <div className="flex flex-row items-center justify-center gap-4">
              {isAdminPlus && (
                <button
                  className="bg-green-500 text-white mt-4 p-2 rounded-lg hover:bg-green-600 w-[60px]"
                  onClick={onUpdateClient}
                  disabled={isSaving}>
                  {isSaving ? <ButtonLoadingIndicator /> : 'Save'}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
