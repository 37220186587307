import ReactLoading from 'react-loading'
import closeSvg from '../images/close2.svg'
import { LearnerStatus } from '../apis/entities/sis.entity'

interface Props {
  data: { newStatus: string }
  isLoading: boolean
  onClose: (confirm: boolean) => void
}

export default function ModalSisConfirmation({ data, isLoading, onClose }: Props): JSX.Element {
  const getStatusText = (status: string) => {
    switch (status) {
      case LearnerStatus.Terminated:
        return 'terminate'
      default:
        return ''
    }
  }

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-auto flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center justify-center px-8 gap-[12px]">
          <p className="text-[16px] font-bold text-left font-roboto w-[100%]">
            Are you sure you want to{' '}
            <span className="font-[700] font-roboto text-[16px] text-[#BD69FE]">{getStatusText(data.newStatus)}</span>{' '}
            this learner?
          </p>
        </div>
        <div className="grow" />
        <div className="flex flex-row justify-center items-center px-8 gap-8">
          <button
            className="button-primary"
            onClick={() => {
              onClose(true)
            }}>
            {isLoading ? (
              <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'16px'} width={'16px'} />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
