import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { attachClosestEdge, type Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button'
import DropIndicator from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box-without-terminal'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview'
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { useRef, useState, useEffect, Fragment } from 'react'
import ReactDOM from 'react-dom'
import invariant from 'tiny-invariant'
import DropDownContent from './DropDownContent'
import { getItemData, isItemData, Item, ItemPosition, useListContext } from './ListContext'
import { Box, Grid, xcss } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'
import mergeRefs from '@atlaskit/ds-lib/merge-refs'

const listItemContainerStyles = xcss({
  position: 'relative',
  backgroundColor: 'elevation.surface',
  borderWidth: 'border.width.0',
  borderBottomWidth: token('border.width', '1px'),
  borderStyle: 'solid',
  borderColor: 'color.border',
  ':last-of-type': {
    borderWidth: 'border.width.0'
  }
})

const listItemStyles = xcss({
  position: 'relative',
  padding: 'space.100'
})

const listItemDisabledStyles = xcss({ opacity: 0.4 })

type DraggableState = { type: 'idle' } | { type: 'preview'; container: HTMLElement } | { type: 'dragging' }

const idleState: DraggableState = { type: 'idle' }
const draggingState: DraggableState = { type: 'dragging' }

const listItemPreviewStyles = xcss({
  paddingBlock: 'space.050',
  paddingInline: 'space.100',
  borderRadius: 'border.radius.100',
  backgroundColor: 'elevation.surface.overlay',
  maxWidth: '360px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const itemLabelStyles = xcss({
  flexGrow: 1,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
})

export function getItemPosition({ index, items }: { index: number; items: Item[] }): ItemPosition {
  if (items.length === 1) {
    return 'only'
  }

  if (index === 0) {
    return 'first'
  }

  if (index === items.length - 1) {
    return 'last'
  }

  return 'middle'
}

export default function ListItem({ item, index, position }: { item: Item; index: number; position: ItemPosition }) {
  const { registerItem, instanceId } = useListContext()

  const ref = useRef<HTMLDivElement>(null)
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null)

  const dragHandleRef = useRef<HTMLButtonElement>(null)

  const [draggableState, setDraggableState] = useState<DraggableState>(idleState)

  useEffect(() => {
    const element = ref.current
    const dragHandle = dragHandleRef.current
    invariant(element)
    invariant(dragHandle)

    const data = getItemData({ item, index, instanceId })

    return combine(
      registerItem({ itemId: item.id, element }),
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: token('space.200', '16px'),
              y: token('space.100', '8px')
            }),
            render({ container }) {
              setDraggableState({ type: 'preview', container })

              return () => setDraggableState(draggingState)
            }
          })
        },
        onDragStart() {
          setDraggableState(draggingState)
        },
        onDrop() {
          setDraggableState(idleState)
        }
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return isItemData(source.data) && source.data.instanceId === instanceId
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom']
          })
        },
        onDrag({ self, source }) {
          const isSource = source.element === element
          if (isSource) {
            setClosestEdge(null)
            return
          }

          const closestEdge = extractClosestEdge(self.data)

          const sourceIndex = source.data.index
          invariant(typeof sourceIndex === 'number')

          const isItemBeforeSource = index === sourceIndex - 1
          const isItemAfterSource = index === sourceIndex + 1

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === 'bottom') || (isItemAfterSource && closestEdge === 'top')

          if (isDropIndicatorHidden) {
            setClosestEdge(null)
            return
          }

          setClosestEdge(closestEdge)
        },
        onDragLeave() {
          setClosestEdge(null)
        },
        onDrop() {
          setClosestEdge(null)
        }
      })
    )
  }, [instanceId, item, index, registerItem])

  return (
    <Fragment>
      <Box ref={ref} xcss={listItemContainerStyles}>
        <Grid
          alignItems="center"
          columnGap="space.050"
          templateColumns="auto 1fr auto"
          xcss={[
            listItemStyles,
            /**
             * We are applying the disabled effect to the inner element so that
             * the border and drop indicator are not affected.
             */
            draggableState.type === 'dragging' && listItemDisabledStyles
          ]}>
          <DropdownMenu
            zIndex={999}
            trigger={({ triggerRef, ...triggerProps }) => (
              <DragHandleButton
                ref={mergeRefs([dragHandleRef, triggerRef])}
                {...triggerProps}
                label={`Reorder ${item.label}`}
              />
            )}>
            <DropdownItemGroup>
              <DropDownContent position={position} index={index} />
            </DropdownItemGroup>
          </DropdownMenu>
          <Box xcss={itemLabelStyles}>{item.label}</Box>
        </Grid>
        {closestEdge && <DropIndicator edge={closestEdge} gap="1px" />}
      </Box>
      {draggableState.type === 'preview' &&
        ReactDOM.createPortal(<Box xcss={listItemPreviewStyles}>{item.label}</Box>, draggableState.container)}
    </Fragment>
  )
}
