export enum LearnerStatus {
  Confirmed = 'Confirmed',
  Enrolled = 'Enrolled',
  InProgress = 'In progress',
  Passed = 'Passed',
  Failed = 'Failed',
  Withdrawn = 'Withdrawn',
  Deferred = 'Deferred',
  Terminated = 'Terminated'
}

export interface ClientSettings {
  adminHub: {
    tableHeaders: {
      [key: string]: any
    }[]
  }
  [key: string]: any
}

export interface SisArchitectureCourse {
  id: string
  name: string
  displayName: string
  externalId: string
  externalSource: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
  clientId: string
  clientName: string
  programId: string
  programName: string
  cohortId: string
  cohortName: string
  cohortCreatedAt: Date
  startDate: Date
  endDate: Date
  censusDate: Date
  learningHours: number
  completionConfig: any
  passCriteria: any
}

export interface SisArchitecture {
  courses: SisArchitectureCourse[]
}

export interface SisLearnerLog {
  id: string
  data: any
  createdAt: Date
}

export interface SisLearner {
  id: string // for course-to-learner mapping id
  firstName: string
  lastName: string
  email: string
  clientId: string
  externalId: string
  externalSource: string
  createdAt: Date
  updatedAt: Date
  managerEmail: string
  managerFirstName: string
  location: string
  businessUnit: string
  raw: any
}

export interface SisLearnerWithCourse extends SisLearner {
  status: string
  note: string
  logs: SisLearnerLog[]
  learnerId: string
  clientId: string
  clientName: string
  programId: string
  programName: string
  cohortId: string
  cohortName: string
  courseId: string
  courseName: string
  courseDisplayName: string
  courseToLearnerId: number
  children: SisLearnerWithCourse[]
}

export interface SisCourse {
  id: string
  name: string
  displayName: string
  externalId: string
  externalSource: string
  startDate: string
  endDate: string
  updatedDate: string
  workflowState: string
  learners: SisLearner[]
}

export interface SisCohort {
  id: string
  name: string
  courses: SisCourse[]
  updatedAt: Date
}

export interface SisProgram {
  id: string
  name: string
  cohorts: SisCohort[]
  updatedAt: Date
  positions: string[]
}

export interface SisClient {
  id: string
  name: string
  externalId: string
  externalSource: string
  programs: SisProgram[]
  settings: ClientSettings
}
