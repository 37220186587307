import closeSvg from '../images/close2.svg'

interface Props {
  message: string
  onClose: (confirmed: boolean) => void
}

export default function ModalSisDeleteConfirmation({ message, onClose }: Props): JSX.Element {
  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-auto flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center justify-center px-8 gap-[12px]">
          <p className="text-[16px] font-bold text-left font-roboto w-[100%]">{message}</p>
        </div>
        <div className="grow" />
        <div className="flex flex-row justify-center items-center px-8 gap-8">
          <button
            className="button-secondary"
            onClick={() => {
              onClose(false)
            }}>
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              onClose(true)
            }}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}
