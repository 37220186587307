import { useState } from 'react'
import { SisLearnerWithCourse } from '../apis/entities/sis.entity'
import closeSvg from '../images/close2.svg'
import Button from '../components/Button'
import { SisAPI } from '../apis/SisAPI'

interface Props {
  learner: SisLearnerWithCourse
  onClose: (updated: boolean, updatedLearner?: SisLearnerWithCourse) => void
}

export default function ModalSisEditNote({ learner, onClose }: Props): JSX.Element {
  const [note, setNote] = useState(learner.note)
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    try {
      setIsSaving(true)
      await SisAPI.updateLearnerWithCourse(learner.clientId, learner.courseId, learner.id, { note })
      learner.note = note
      onClose(true, learner)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-auto flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center gap-[24px] px-8 w-full">
          <textarea
            value={note || ''}
            onChange={e => setNote(e.target.value)}
            style={{
              width: '50vw',
              height: '50vh',
              border: '1px solid #D5D5DB',
              borderRadius: '3px',
              marginTop: '16px',
              padding: '16px 0px 36px 12px',
              color: '#000',
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 600,
              lineHeight: '20px',
              fontStyle: 'normal',
              outline: 'none'
            }}
            onKeyDown={async e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault() // Prevent the default action to avoid adding a new line
                handleSave()
              }
            }}
          />
          <Button label="Save" disabled={isSaving} isLoading={isSaving} onClick={handleSave} />
        </div>
      </div>
    </div>
  )
}
