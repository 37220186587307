import { Link } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { useDataContext } from '../context/DataProvider'
import { useAuth } from '../context/AuthProvider'
import { Organisation } from '../apis/entities/organisation.entity'
import { useState } from 'react'
import ModalSisCms from '../modals/ModalSisCms'

export default function Learners() {
  const { clients } = useDataContext()
  const { isSuperAdmin, isAdmin } = useAuth()
  const [selectedClientId, setSelectedClientId] = useState('')
  const [showEdit, setShowEdit] = useState(false)

  const isAdminPlus = () => {
    return isAdmin() || isSuperAdmin()
  }

  const onClickEdit = async (client: Organisation) => {
    setSelectedClientId(client.id)
    setShowEdit(true)
  }

  const renderClients = () => {
    return clients?.map((item, i) => {
      return (
        <div className="flex flex-col gap-2" key={`client-${i}`}>
          <Link to={'/learners/' + item.id} key={item.id}>
            <div className="rounded-lg bg-white px-4 py-5 shadow">
              <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-20 flex items-center justify-center">
                {item.name}
              </div>
            </div>
          </Link>
          <div className="flex justify-end p-1">
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', width: '100%' }}>
              <div />
              <button style={{ fontSize: '14px' }} className="text-white" onClick={() => onClickEdit(item)}>
                {isAdminPlus() ? 'Manage' : 'View'} content
              </button>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4">
        <div className="page-title">Learners</div>
        <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
      </div>
      {showEdit && (
        <ModalSisCms clientId={selectedClientId} onClose={() => setShowEdit(false)} isAdminPlus={isAdminPlus()} />
      )}
    </SidebarLayout>
  )
}
