import { useEffect, useState } from 'react'
import SidebarLayout from '../components/SidebarLayout'
import { AdminAPI } from '../apis/AdminAPI'
import Modal, { Props as ReactModalProps } from 'react-modal'
import Svg from '../components/Svg'
import { UserDetails } from '../apis/entities/user.entity'
import LoadingIndicator from '../components/LoadingIndicator'
import { useLoadingContext } from '../context/LoadingProvider'
import { isEmail } from '../utils/stringUtils'
import { toastError, toastSuccess } from '../utils/toast'
import { RoleDetails } from '../apis/entities/role.entity'

function MyModal(props: ReactModalProps) {
  // @ts-ignore
  return <Modal {...props}>{props.children}</Modal>
}

export default function LXE() {
  const { isLoading, setIsLoading } = useLoadingContext()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [roles, setRoles] = useState<RoleDetails[]>([])
  const [assignEmail, setAssignEmail] = useState('')
  const [assignRole, setAssignRole] = useState('')
  const [users, setUsers] = useState<UserDetails[]>([])
  const [currentUsers, setCurrentUsers] = useState<UserDetails[]>([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [reloadCount, setReloadCount] = useState(0)

  enum ModalMode {
    Delete,
    Invite
  }

  const [modalMode, setModalMode] = useState(ModalMode.Delete)
  Modal.setAppElement('#root')

  useEffect(() => {
    const fetchData = async () => {
      const data = await AdminAPI.getLXEUsers()
      if (data) {
        setUsers(data)
      }
      let roles = await AdminAPI.getRoles()
      if (roles) {
        // filter LX Engine roles
        roles = roles.filter((item, i) => {
          return item.name.startsWith('Lx')
        })
        setRoles(roles)
      }
    }
    setIsLoading(true)
    fetchData().finally(() => {
      setIsLoading(false)
    })
  }, [reloadCount, setIsLoading])

  const reload = async () => {
    console.log('reload...')
    setReloadCount(reloadCount + 1)
  }

  const handleDeleteClicked = (user: UserDetails) => {
    console.log(`handle delete, ${user}`)
    setCurrentUsers([user])
    setModalMode(ModalMode.Delete)
    openModal()
  }

  const handleConfirmDeleteUsers = async () => {
    console.log(`handle delete users, ${currentUsers}`)
    const deleteIds = currentUsers.map((item, i) => {
      return item.id
    })
    setIsLoading(true)

    try {
      const userId = deleteIds[0]
      const user = users.filter((item, i) => {
        return item.id === userId
      })
      const roleId = user[0].roles[0].id
      const result = await AdminAPI.deleteRoleFromUser(userId, roleId)
      if (result) {
        toastSuccess(`User ${currentUsers[0].email} has been deleted`)
      } else {
        toastError(`Failed to delete user ${currentUsers[0].email}`)
      }
    } catch (error) {
      toastError(`${error}`)
    }
    closeModal()
    reload()
  }

  const handleInviteClicked = async () => {
    if (!firstName) {
      toastError('Please input the first name')
      return
    }
    if (!lastName) {
      toastError('Please input the last name')
      return
    }
    if (!isEmail(email)) {
      toastError('Please input a correct email')
      return
    }
    if (!role) {
      toastError('Please select a role')
      return
    }
    console.log(`handle invite`, email, role)

    // role is roleId from options
    const roleName =
      roles.find((item, i) => {
        return item.id === role
      })?.name || ''

    try {
      const result = await AdminAPI.inviteUser({
        email: email,
        role: roleName,
        firstName: firstName,
        lastName: lastName
      })
      // console.log(result)
      toastSuccess(`Invitation has been sent to ${email}`)
      const userId = result.data.inviteUser
      // console.log(`assign role ${role} to user ${userId}`)
      if (userId) {
        await AdminAPI.assignRoleToUser(userId, role)
      }
      reload()
    } catch (error) {
      toastError(`${error}`)
    }
  }

  const handleAssignClicked = async () => {
    if (!isEmail(assignEmail)) {
      toastError('Please input a correct email')
      return
    }
    try {
      const result = await AdminAPI.assignRoleByEmail(assignEmail, assignRole)
      if (result) {
        toastSuccess(`Role has been assigned to ${assignEmail}`)
        reload()
      } else {
        toastError(`Failed to assign role to ${assignEmail}`)
      }
    } catch (error) {
      toastError(`${error}`)
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    reload()
  }

  const afterOpenModal = () => {
    // document.body.style.overflow = 'unset';
  }

  const afterCloseModal = () => {
    // document.body.style.overflow = 'auto';
  }

  const renderUserRows = () => {
    return users.map((item, i) => {
      return (
        <tr key={item.id}>
          <td>
            {item.auth0user?.given_name || 'N/A'} {item.auth0user?.family_name || ''}
          </td>
          <td>{item.email || '(email not available)'}</td>
          <td>
            {item.roles
              .map(r => {
                return r.name
              })
              .join(', ')}
          </td>
          <td>{item.status}</td>
          <td>
            <button
              type="button"
              onClick={() => {
                handleDeleteClicked(item)
              }}
              className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-1 focus:ring-purple-300 font-medium rounded-lg text-sm dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 px-5 py-2.5 my-1">
              Delete User
            </button>
          </td>
        </tr>
      )
    })
  }

  const renderRoleOptions = () => {
    return roles.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      )
    })
  }

  return (
    <SidebarLayout>
      {/* page content */}
      <div className="flex grow flex-col gap-8 p-16">
        <div className="flex flex-col items-start gap-8">
          <div className="page-title">{'LX Engine'}</div>

          {/* invite */}
          <div className="section-heading">Invite a new user</div>
          <div className="flex flex-row gap-4">
            <input
              type="text"
              id="firstName"
              className="w-80 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="First name"
              onChange={e => setFirstName(e.target.value)}
              required></input>
            <input
              type="text"
              id="lastName"
              className="w-80 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="Last name"
              onChange={e => setLastName(e.target.value)}
              required></input>
          </div>
          <div className="flex flex-row gap-4">
            <input
              type="text"
              id="email"
              className="w-96 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="email"
              onChange={e => setEmail(e.target.value)}
              required></input>

            <select
              className="border-r-[12px] pl-4 pr-1 border-purple-600 hover:border-purple-700 bg-purple-700 rounded-lg text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onChange={e => setRole(e.target.value)}
              defaultValue="">
              <option value={''} disabled hidden>
                Role
              </option>
              {renderRoleOptions()}
            </select>

            <button
              type="button"
              className="rounded-lg bg-purple-700 px-5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onClick={handleInviteClicked}>
              Send invitation
            </button>
          </div>
        </div>

        {/* assign */}
        <div className="section-heading text-left">Assign role to existing user</div>
        <div className="flex flex-row gap-4">
          <input
            type="text"
            id="email"
            className="w-96 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
            placeholder="email"
            onChange={e => setAssignEmail(e.target.value)}
            required></input>

          <select
            className="border-r-[12px] pl-4 pr-1 border-purple-600 hover:border-purple-700 bg-purple-700 rounded-lg text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
            onChange={e => setAssignRole(e.target.value)}
            defaultValue="">
            <option value={''} disabled hidden>
              Role
            </option>
            {renderRoleOptions()}
          </select>

          <button
            type="button"
            className="rounded-lg bg-purple-700 px-5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
            onClick={handleAssignClicked}>
            Assign role
          </button>
        </div>

        <div className="section-heading text-left">User list</div>
        <table className="table-auto text-left text-white">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderUserRows()}</tbody>
        </table>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
        {/* prevent overflow scroll clipping empty area */}
        <div className="mt-20">&nbsp;</div>
      </div>

      <MyModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterCloseModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Modal"
        className="Modal relative"
        overlayClassName="Overlay"
        closeTimeoutMS={250}>
        {/* close button */}
        <div className="flex justify-end">
          <button className="w-6" onClick={closeModal}>
            <Svg icon="close" />
          </button>
        </div>
        {/* content */}
        {modalMode === ModalMode.Delete && (
          <>
            <div className="w-96 flex flex-col gap-4">
              <div className="text-left font-medium">
                Delete user '{currentUsers.length > 0 ? currentUsers[0].email : ''}'?
              </div>
              <div>Deleted user won't be able to access the LX Engine</div>
              <div className="flex gap-6 justify-end mt-4">
                <button
                  className="mb-2 rounded-lg px-5 py-2.5 text-sm font-medium text-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:focus:ring-purple-900"
                  onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="mb-2 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                  onClick={handleConfirmDeleteUsers}>
                  Delete
                </button>
              </div>
            </div>
          </>
        )}
        {modalMode === ModalMode.Invite && <>{isLoading && <LoadingIndicator />}</>}
      </MyModal>
    </SidebarLayout>
  )
}
