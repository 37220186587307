import EventEmitter from 'eventemitter3'

const eventEmitter = new EventEmitter()
const Emitter = {
  on: (event: string, fn: any) => eventEmitter.on(event, fn),
  once: (event: string, fn: any) => eventEmitter.once(event, fn),
  off: (event: string, fn: any) => eventEmitter.off(event, fn),
  emit: (event: string, payload: any) => eventEmitter.emit(event, payload)
}
Object.freeze(Emitter)
export default Emitter

export enum Events {
  // drop down menu
  OnConfirmedPreselectedItem = 'OnConfirmedPreselectedItem',
  // EOI
  OnSucceedInviteOrRejectLearners = 'OnSucceedInviteOrRejectLearners',
  OnUpdatedLearnerReport = 'OnUpdatedLearnerReport',
  OnAddedLearnerReport = 'OnAddedLearnerReport',
  // SIS
  OnUpdatedLearner = 'OnUpdatedLearner',
  OnAddedLearner = 'OnAddedLearner',
  OnClosedManageProgram = 'OnClosedManageProgram',
  OnShowEditCourseMapping = 'OnShowEditCourseMapping',
  OnClosedEditCourseMapping = 'OnClosedEditCourseMapping',
  OnShowEditCourse = 'OnShowEditCourse',
  OnClosedEditCourse = 'OnClosedEditCourse'
}
